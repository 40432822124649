import React, { useState, useEffect, Fragment } from "react";
import PowerChart from "./PowerChart/PowerChart";
import EnergyChart from "./EnergyChart/EnergyChart";
import "./HistoricalDataWrapper.css";

const HistoricalDataWrapper = (props) => {
  const [parsedData, setParsedData] = useState([]);

  useEffect(() => {
    if (props.data && props.data.length !== 0) {
      console.log(props.data.length);

      const dataNew = props.data
        // .slice(props.data.length - 2000, props.data.length - 1)
        .map((el) => {
          if (props.dashboardType === "ZEE") {
            return {
              date: new Date(el[0] * 1000),
              import:
                props.mode === "power"
                  ? el[1] > 0
                    ? el[1]
                    : 0
                  : props.mode === "energy"
                  ? el[1]
                  : null,
              export:
                props.mode === "power"
                  ? el[1] < 0
                    ? el[1] * -1
                    : 0
                  : props.mode === "energy"
                  ? el[2]
                  : null,
              pv:
                props.mode === "power"
                  ? el[2]
                  : props.mode === "energy"
                  ? el[3]
                  : null,
            };
          } else {
            return {
              date: new Date(el[0] * 1000),
              import:
                props.mode === "power"
                  ? el[1] > 0
                    ? el[1]
                    : 0
                  : props.mode === "energy"
                  ? el[1]
                  : null,
              export:
                props.mode === "power"
                  ? el[1] < 0
                    ? el[1] * -1
                    : 0
                  : props.mode === "energy"
                  ? el[2]
                  : null,
            };
          }
        });
      setParsedData(dataNew);
    } else if (props.data && props.data.length === 0) {
      // Empty data case
      setParsedData([{}]);
    } else {
      // Data Init Case
      setParsedData([]);
    }
  }, [props.data]);

  return (
    <Fragment>
      {props.mode === "power" ? (
        <PowerChart
          data={parsedData}
          fs={props.fs}
          dashboardType={props.dashboardType}
          fetchPowerData={props.fetchPowerData}
          isMobile={props.isMobile}
          isTablet={props.isTablet}
          setShowMobileModalRS={props.setShowMobileModalRS}
          startDateEpoch={props.startDateEpoch}
          endDateEpoch={props.endDateEpoch}
          rangeSelModalFlag={props.rangeSelModalFlag}
          setRangeSelModalFlag={props.setRangeSelModalFlag}
          powerChartLiveData={props.powerChartLiveData}
        />
      ) : props.mode === "energy" ? (
        <EnergyChart
          data={parsedData}
          fs={props.fs}
          dashboardType={props.dashboardType}
          fetchEnergyData={props.fetchEnergyData}
          isMobile={props.isMobile}
          isTablet={props.isTablet}
          setShowMobileModalRS={props.setShowMobileModalRS}
          startDateEpoch={props.startDateEpoch}
          endDateEpoch={props.endDateEpoch}
          rangeSelModalFlag={props.rangeSelModalFlag}
          setRangeSelModalFlag={props.setRangeSelModalFlag}
          energyChartLiveData={props.energyChartLiveData}
        />
      ) : null}
    </Fragment>
  );
};

export default HistoricalDataWrapper;

import React, { useState, useEffect, Fragment } from "react";
import Modal from "../Modal/Modal";
import MobileModal from "../MobileModal/MobileModal";
import Card from "react-bootstrap/Card";
import Home from "../../pages/Home";
import Account from "../../pages/Account";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import deleteIcon from "../../images/home_delete_icon.svg";
import saveIcon from "../../images/time_save_icon.svg";
import cancelIcon from "../../images/time_cancel_icon.svg";
import appIcon from "../../images/admin_interface/app_icon.png";
import backArrow from "../../images/home_back_arrow.svg";
import AccountIcon from "../../images/navbar_account_icon.svg";
import userIcon from "../../images/navbar_account_icon.svg";
import filterOff from "../../images/admin_interface/filter_off_icon.svg";
import filterOn from "../../images/admin_interface/filter_on_icon.svg";
import searchIcon from "../../images/admin_interface/search_icon.svg";
import searchCloseIcon from "../../images/admin_interface/search_close.svg";
import arrowDown from "../../images/dashboard/arrow_left.svg";
import staffIcon from "../../images/admin_interface/admin_icon.png";
import nonStaffIcon from "../../images/admin_interface/user_icon.png";
import baseURL from "../../baseURL";
import "./AdminDash.css";

const AdminDash = (props) => {
  const [fetchErr, setFetchErr] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [initUserList, setInitUserList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [authMsg, setAuthMsg] = useState("");
  const [selUserIdToAct, setSelUserIdToAct] = useState(null);
  const [selUserActFlag, setSelUserActFlag] = useState(null);
  const [selUserIdToSub, setSelUserIdToSub] = useState(null);
  const [selUserSubFlag, setSelUserSubFlag] = useState(null);
  const [selUserIdToDel, setSelUserIdToDel] = useState(null);
  const [selEmailToDel, setSelEmailToDel] = useState(null);
  const [selUserSubFlagToDel, setSelUserSubFlagToDel] = useState(null);
  const [userJustActivated, setUserJustActivated] = useState(null);
  const [userJustSubscribed, setUserJustSubscribed] = useState(null);
  // Delete user modal
  const [isModalDelUser, setIsModalDelUser] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [delError, setDelError] = useState(false);
  const [delSuccess, setDelSuccess] = useState(false);
  const [delErrMsg, setDelErrMsg] = useState("");
  // App-in-app
  const [isApp, setIsApp] = useState(false);
  const [isAccount, setIsAccount] = useState(false);
  const [selUserIdToRunApp, setSelUserIdToRunApp] = useState("");
  const [selEmailToRunApp, setSelEmailToRunApp] = useState("");
  const [isAdminToRunApp, setIsAdminToRunApp] = useState(false);
  const [transitionStateApp, setTransitionStateApp] = useState(false);
  // Advanced filters
  // Sort
  const [isSorted, setIsSorted] = useState(false);
  // Active filter
  const [isFiltered, setIsFiltered] = useState(false);
  const [filterType, setFilterType] = useState("");
  // User search filter
  const [searchInput, setSearchInput] = useState("");
  const [isUserSearch, setIsUserSearch] = useState(false);
  const [isNameSearch, setIsNameSearch] = useState(false);
  // Mobile modal
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [checkedRadio, setCheckedRadio] = useState("email");
  const [clonedFilterState, setClonedFilterState] = useState({});
  const [clonedUserList, setClonedUserList] = useState([]);
  // Tabs
  const [viewType, setViewType] = useState("user");

  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const userId =
    localStorage.getItem("userId") || sessionStorage.getItem("userId");

  useEffect(() => {
    if (props.loaded === true) {
      getUserList();
    }
    if (props.loaded === false) {
      setFetchErr(true);
    }
  }, [props.loaded]);

  const getUserList = async () => {
    try {
      const head = new Headers();
      head.append("Authorization", "Bearer " + token);

      const url = new URL(`./api/v1/users`, baseURL);

      const response = await fetch(url, {
        method: "GET",
        headers: head,
      });

      let data = await response.json();
      if (data.detail) {
        setAuthMsg("Access denied!");
      } else {
        let adminEmail = data.filter((user) => user.id === Number(userId))[0]
          .email;
        setAdminEmail(adminEmail);
        let removeThisUser = data.filter((user) => user.id !== Number(userId));
        let users = removeThisUser.filter((user) => !user.is_staff);
        let admins = removeThisUser.filter((user) => user.is_staff);
        let sortUserListById = handleSortToggle(users, "id", "desc", "init");
        let sortAdminListById = handleSortToggle(admins, "id", "desc", "init");
        const clonedInitialUserArr = sortUserListById.map((item) => ({
          ...item,
        }));
        const clonedInitialAdminArr = sortAdminListById.map((item) => ({
          ...item,
        }));
        const concatName = clonedInitialUserArr.map((item) => ({
          ...item,
          name: item.first_name + " " + item.last_name,
        }));
        setInitUserList(concatName);
        setUserList(sortUserListById);
        setAdminList(clonedInitialAdminArr);
        sortUserListById.length === 0 && clonedInitialAdminArr.length === 0
          ? setAuthMsg("No users/admins available!")
          : sortUserListById.length === 0 && clonedInitialAdminArr.length !== 0
          ? setAuthMsg("No users available")
          : sortUserListById.length !== 0 &&
            clonedInitialAdminArr.length === 0 &&
            setAuthMsg("No other admins available");
      }
    } catch (error) {
      console.log(error);
      setFetchErr(true);
    }
  };

  const getUserListUpdate = async () => {
    try {
      const head = new Headers();
      head.append("Authorization", "Bearer " + token);

      const url = new URL(`./api/v1/users`, baseURL);

      const response = await fetch(url, {
        method: "GET",
        headers: head,
      });

      let data = await response.json();
      if (data.detail) {
        setAuthMsg("Access denied!");
      } else {
        let currentFilterData = data.filter((o1) =>
          userList.some((o2) => o1.id === o2.id)
        );
        let sortedData = !isSorted
          ? currentFilterData.sort(
              (a, b) => parseFloat(b["id"]) - parseFloat(a["id"])
            )
          : currentFilterData.sort(
              (a, b) => parseFloat(a["id"]) - parseFloat(b["id"])
            );
        setUserList(sortedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const activateUser = async () => {
    setSelUserIdToAct(null);
    setSelUserActFlag(null);

    const alterActiveUser = (arr, flag) =>
      arr.map((obj) => {
        if (obj.id === selUserIdToAct) {
          obj.is_active = flag ? selUserActFlag : !selUserActFlag;
          return obj;
        } else {
          return obj;
        }
      });

    try {
      const url = new URL(`./api/v1/users/${selUserIdToAct}/activate`, baseURL);

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          activate: selUserActFlag,
        }),
      });

      if (!response.ok) {
        // alert("Failed!");
        setInitUserList(alterActiveUser(initUserList));
        setUserList(alterActiveUser(userList));
      } else {
        let data = await response.json();
        console.log(data);
        // getUserList();
        // Alter initial user list from frontend instead of calling getUserList (to keep filters)
        setInitUserList(alterActiveUser(initUserList, true));
        // Reapply active filter if exists
        isFiltered &&
          (filterType === "active" ? selectActive() : selectInactive());
        selUserActFlag && setUserJustActivated(selUserIdToAct);
        setTimeout(() => {
          setUserJustActivated(null);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setInitUserList(alterActiveUser(initUserList, false));
      setUserList(alterActiveUser(userList, false));
    }
  };

  const subscribeUser = async () => {
    setSelUserIdToSub(null);
    setSelUserSubFlag(null);

    const alterSubscribedUser = (arr, flag) =>
      arr.map((obj) => {
        if (obj.id === selUserIdToSub) {
          obj.is_subscribed = flag ? selUserSubFlag : !selUserSubFlag;
          return obj;
        } else {
          return obj;
        }
      });

    try {
      const url = new URL(
        `./api/v1/users/${selUserIdToSub}/subscribe`,
        baseURL
      );

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          subscribe: selUserSubFlag,
        }),
      });

      if (!response.ok) {
        // alert("Failed!");
        setInitUserList(alterSubscribedUser(initUserList));
        setUserList(alterSubscribedUser(userList));
      } else {
        let data = await response.json();
        console.log(data);
        // getUserList();
        // Alter initial user list from frontend instead of calling getUserList (to keep filters)
        setInitUserList(alterSubscribedUser(initUserList, true));
        // Reapply subscribed filter if exists
        isFiltered &&
          (filterType === "subscribed"
            ? selectSubscribed()
            : selectUnsubscribed());
        selUserSubFlag && setUserJustSubscribed(selUserIdToSub);
        setTimeout(() => {
          setUserJustSubscribed(null);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setInitUserList(alterSubscribedUser(initUserList, false));
      setUserList(alterSubscribedUser(userList, false));
    }
  };

  const handleCloseModalDelUser = () => {
    setDelLoading(false);
    setDelSuccess(false);
    setDelError(false);
    setDelErrMsg("");
    setIsModalDelUser(!isModalDelUser);
  };

  const JSXContentDelUser = (
    <div style={{ display: "block" }}>
      <p>
        Are you sure you want to delete user{" "}
        <span
          id='admin-dash-user-del-modal-user-email'
          className='delete-user-email-span'>
          {selEmailToDel}
        </span>
        ?
      </p>
      <div
        style={{
          color: "orange",
          fontSize: "12px",
          fontWeight: "bold",
          marginBottom: "4px",
        }}>
        This action cannot be UNDONE!
      </div>
      {selUserSubFlagToDel && (
        <div
          id='admin-dash-user-del-modal-subscribed-user-alert'
          style={{ color: "red", fontSize: "14px", fontWeight: "bold" }}>
          <span style={{ textDecoration: "underline" }}>WARNING:</span> This is
          a subscribed user!
        </div>
      )}
    </div>
  );

  const deleteUser = async () => {
    setDelLoading(true);
    setDelError(false);
    setDelSuccess(false);

    try {
      const head = new Headers();
      head.append("Authorization", "Bearer " + token);

      const url = new URL(`./api/v1/users/${selUserIdToDel}`, baseURL);

      const response = await fetch(url, {
        method: "DELETE",
        headers: head,
      });

      if (!response.ok) {
        setDelLoading(false);
        setDelError(true);
        setDelErrMsg("Failed to delete user!");
      } else {
        setDelLoading(false);
        setDelSuccess(true);
        handleCloseModalDelUser();
        // getUserList();
        // Alter initial user list from frontend instead of calling getUserList (to keep filters)
        const deleteUserList = (arr) =>
          arr.filter((obj) => obj.id !== selUserIdToDel);

        setUserList(deleteUserList(userList));
        setInitUserList(deleteUserList(initUserList));
      }
      setTimeout(() => {
        setDelSuccess(false);
        setDelError(false);
        setDelErrMsg("");
      }, 3000);
    } catch (error) {
      setDelLoading(false);
      setDelError(true);
      setDelErrMsg("Failed to delete user!");
      setTimeout(() => {
        setDelError(false);
        setDelErrMsg("");
      }, 3000);
    }
  };

  const handleAppClick = (e, userId, email, isAdmin) => {
    setIsApp(true);
    setTransitionStateApp((transitionStateApp) => !transitionStateApp);
    setSelUserIdToRunApp(userId);
    setIsAdminToRunApp(isAdmin);
    setSelEmailToRunApp(email);
    props.setLoaded(true);
  };

  const handleAppBack = (e) => {
    setIsApp(false);
    setIsAccount(false);
    setTransitionStateApp((transitionStateApp) => !transitionStateApp);
    getUserListUpdate();
  };

  const handleSortToggle = (arr, param, dir) => {
    setSelUserIdToAct(null);
    setSelUserActFlag(null);
    setIsSorted(!isSorted);
    return dir === "asc"
      ? arr.sort((a, b) => parseFloat(b[param]) - parseFloat(a[param]))
      : arr.sort((a, b) => parseFloat(a[param]) - parseFloat(b[param]));
  };

  const searchItems = (searchKey, searchValue) => {
    setSearchInput(searchValue);
    let filteredData;
    if (isFiltered) {
      if (filterType === "active" || filterType === "inactive") {
        filteredData = initUserList
          .filter((p) => (filterType === "active" ? p.is_active : !p.is_active))
          .filter((item) => {
            return item[searchKey]
              .toLowerCase()
              .includes(searchValue.toLowerCase());
          });
      } else {
        filteredData = initUserList
          .filter((p) =>
            filterType === "verified" ? p.is_verified : !p.is_verified
          )
          .filter((item) => {
            return item[searchKey]
              .toLowerCase()
              .includes(searchValue.toLowerCase());
          });
      }
    } else {
      filteredData = initUserList.filter((item) => {
        return item[searchKey]
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
    }
    setUserList(filteredData);
  };

  const handleSubmitFilters = () => {
    setShowMobileModal(false);
  };

  const handleClearAllFilters = () => {
    setShowMobileModal(false);
    setSearchInput("");
    const sortedAsc = handleSortToggle(initUserList, "id", "desc");
    setUserList(sortedAsc);
    setIsSorted(true);
    setIsFiltered(false);
    setFilterType("");
  };

  const selectActive = () => {
    let activeUsers;
    if (searchInput !== "") {
      let searchedInput = initUserList.filter((item) => {
        return item[isUserSearch ? "email" : "name"]
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      activeUsers = searchedInput.filter((p) => p.is_active);
    } else {
      activeUsers = initUserList.filter((p) => p.is_active);
    }
    setUserList(activeUsers);
    setIsFiltered(true);
    setFilterType("active");
  };

  const selectInactive = () => {
    let inactiveUsers;
    if (searchInput !== "") {
      let searchedInput = initUserList.filter((item) => {
        return item[isUserSearch ? "email" : "name"]
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      inactiveUsers = searchedInput.filter((p) => !p.is_active);
    } else {
      inactiveUsers = initUserList.filter((p) => !p.is_active);
    }
    setUserList(inactiveUsers);
    setIsFiltered(true);
    setFilterType("inactive");
  };

  const selectVerified = () => {
    let verifiedUsers;
    if (searchInput !== "") {
      let searchedInput = initUserList.filter((item) => {
        return item[isUserSearch ? "email" : "name"]
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      verifiedUsers = searchedInput.filter((p) => p.is_verified);
    } else {
      verifiedUsers = initUserList.filter((p) => p.is_verified);
    }
    setUserList(verifiedUsers);
    setIsFiltered(true);
    setFilterType("verified");
  };

  const selectUnverified = () => {
    let unverifiedUsers;
    if (searchInput !== "") {
      let searchedInput = initUserList.filter((item) => {
        return item[isUserSearch ? "email" : "name"]
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      unverifiedUsers = searchedInput.filter((p) => !p.is_verified);
    } else {
      unverifiedUsers = initUserList.filter((p) => !p.is_verified);
    }
    setUserList(unverifiedUsers);
    setIsFiltered(true);
    setFilterType("unverified");
  };

  const selectSubscribed = () => {
    let subUsers;
    if (searchInput !== "") {
      let searchedInput = initUserList.filter((item) => {
        return item[isUserSearch ? "email" : "name"]
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      subUsers = searchedInput.filter((p) => p.is_subscribed);
    } else {
      subUsers = initUserList.filter((p) => p.is_subscribed);
    }
    setUserList(subUsers);
    setIsFiltered(true);
    setFilterType("subscribed");
  };

  const selectUnsubscribed = () => {
    let unSubUsers;
    if (searchInput !== "") {
      let searchedInput = initUserList.filter((item) => {
        return item[isUserSearch ? "email" : "name"]
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      unSubUsers = searchedInput.filter((p) => !p.is_subscribed);
    } else {
      unSubUsers = initUserList.filter((p) => !p.is_subscribed);
    }
    setUserList(unSubUsers);
    setIsFiltered(true);
    setFilterType("unsubscribed");
  };

  const clearActiveFilter = () => {
    if (searchInput !== "") {
      let searchedInput = initUserList.filter((item) => {
        return item[isUserSearch ? "email" : "name"]
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setUserList(searchedInput);
    } else {
      setUserList(initUserList);
    }
    setIsFiltered(false);
    setFilterType("");
  };

  const toggleSearchBox = () => {
    setSearchInput("");
    if (isFiltered) {
      if (filterType === "active" || filterType === "inactive") {
        setUserList(
          initUserList.filter((p) =>
            filterType === "active" ? p.is_active : !p.is_active
          )
        );
      } else {
        setUserList(
          initUserList.filter((p) =>
            filterType === "verified" ? p.is_verified : !p.is_verified
          )
        );
      }
    } else {
      setUserList(initUserList);
    }
  };

  const resetUserActiveState = (id) => {
    const userListCopy = userList.map((item) => ({
      ...item,
    }));
    const initUserListCopy = initUserList.map((item) => ({
      ...item,
    }));
    const initUserObj = initUserListCopy.filter((val) => {
      return val.id === id;
    });
    const initUserActState = initUserObj[0].is_active;
    const newUserList = userListCopy.map((obj) => {
      if (obj.id === id) {
        obj.is_active = initUserActState;
        return obj;
      } else {
        return obj;
      }
    });
    setUserList(newUserList);
    setSelUserIdToAct(null);
    setSelUserActFlag(null);
  };

  const resetUserSubscribeState = (id) => {
    const userListCopy = userList.map((item) => ({
      ...item,
    }));
    const initUserListCopy = initUserList.map((item) => ({
      ...item,
    }));
    const initUserObj = initUserListCopy.filter((val) => {
      return val.id === id;
    });
    const initUserActState = initUserObj[0].is_subscribed;
    const newUserList = userListCopy.map((obj) => {
      if (obj.id === id) {
        obj.is_subscribed = initUserActState;
        return obj;
      } else {
        return obj;
      }
    });
    setUserList(newUserList);
    setSelUserIdToSub(null);
    setSelUserSubFlag(null);
  };

  const cloneFilterState = () => {
    const userListCopy = userList.map((item) => ({
      ...item,
    }));
    setClonedUserList(userListCopy);
    setClonedFilterState({
      search: searchInput,
      userSearch: isUserSearch,
      nameSearch: isNameSearch,
      checkedRadio: checkedRadio,
      sorted: isSorted,
      activeFilter: isFiltered,
      activeFilterType: filterType,
    });
  };

  const cancelFilters = () => {
    setUserList(clonedUserList);
    setIsSorted(clonedFilterState.sorted);
    setIsFiltered(clonedFilterState.activeFilter);
    setFilterType(clonedFilterState.activeFilterType);
    setSearchInput(clonedFilterState.search);
    setIsUserSearch(clonedFilterState.userSearch);
    setIsNameSearch(clonedFilterState.nameSearch);
    setCheckedRadio(clonedFilterState.checkedRadio);
  };

  const userTable = initUserList && initUserList.length !== 0 && (
    <div id='user-table-wrapper'>
      <div id='user-table-header' className='list-group-item'>
        <span>
          ID{" "}
          <svg
            id='admin-dash-id-sort-icon'
            width='28'
            height='28'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleSortToggle(userList, "id", isSorted ? "asc" : "desc");
              handleSortToggle(initUserList, "id", isSorted ? "asc" : "desc");
            }}>
            <path
              d='M24 6L33 19L15 19L24 6Z'
              stroke={!isSorted ? "limegreen" : "#A4A5AA"}
              strokeWidth='4'
              strokeLinejoin='round'
            />
            <path
              d='M33 29L24 42L15 29L33 29Z'
              stroke={isSorted ? "limegreen" : "#A4A5AA"}
              strokeWidth='4'
              strokeLinejoin='round'
            />
          </svg>
        </span>
        <span>
          {!isUserSearch && "EMAIL"}
          {isUserSearch && (
            <input
              id='admin-dash-email-search-input'
              className='input search-input'
              type='text'
              maxLength={20}
              placeholder='Search...'
              autoFocus
              onChange={(e) => searchItems("email", e.target.value)}
            />
          )}
          <img
            id='admin-dash-email-search-icon'
            className='search-icon'
            src={isUserSearch ? searchCloseIcon : searchIcon}
            alt={isUserSearch ? "clear search" : "search users"}
            onClick={() => {
              setIsUserSearch(!isUserSearch);
              setIsNameSearch(false);
              toggleSearchBox();
            }}
          />
        </span>
        <span>
          {!isNameSearch && "FULL NAME"}
          {isNameSearch && (
            <input
              id='admin-dash-full-name-search-input'
              className='input search-input'
              type='text'
              maxLength={20}
              placeholder='Search...'
              autoFocus
              onChange={(e) => searchItems("name", e.target.value)}
            />
          )}
          <img
            id='admin-dash-full-name-search-icon'
            className='search-icon'
            src={isNameSearch ? searchCloseIcon : searchIcon}
            alt={isNameSearch ? "clear search" : "search users"}
            onClick={() => {
              setIsUserSearch(false);
              setIsNameSearch(!isNameSearch);
              toggleSearchBox();
            }}
          />
        </span>
        <span className='active-header'>
          ACTIVE?
          <DropdownButton
            id='admin-dash-active-filter-icon'
            title={
              <svg
                width='24'
                height='24'
                viewBox='0 0 42 42'
                fill={
                  isFiltered &&
                  (filterType === "active" || filterType === "inactive")
                    ? "limegreen"
                    : "#A4A5AA"
                }
                xmlns='http://www.w3.org/2000/svg'>
                <path d='M35 5.25L7 5.25C6.53587 5.25 6.09075 5.43437 5.76256 5.76256C5.43437 6.09075 5.25 6.53587 5.25 7L5.25 11.5325C5.25 12.4478 5.62275 13.3473 6.27025 13.9948L15.75 23.4745L15.75 36.75C15.7503 37.0482 15.8268 37.3413 15.9721 37.6016C16.1174 37.862 16.3267 38.081 16.5803 38.2378C16.8338 38.3946 17.1232 38.4842 17.4211 38.4979C17.7189 38.5116 18.0153 38.4491 18.2822 38.3163L25.2822 34.8163C25.8755 34.5188 26.25 33.9133 26.25 33.25V23.4745L35.7297 13.9948C36.3772 13.3473 36.75 12.4478 36.75 11.5325V7C36.75 6.53587 36.5656 6.09075 36.2374 5.76256C35.9092 5.43437 35.4641 5.25 35 5.25V5.25ZM23.2628 21.5128C23.0999 21.675 22.9708 21.8678 22.8828 22.0802C22.7948 22.2925 22.7497 22.5201 22.75 22.75V32.1685L19.25 33.9185L19.25 22.75C19.2503 22.5201 19.2052 22.2925 19.1172 22.0802C19.0292 21.8678 18.9001 21.675 18.7372 21.5128L8.75 11.5325V8.75L33.2517 8.75L33.2553 11.5203L23.2628 21.5128V21.5128Z' />
              </svg>
            }
            align='end'
            className='three-dots dots-button active-filter'
            onClick={() =>
              (filterType === "verified" ||
                filterType === "unverified" ||
                filterType === "subscribed" ||
                filterType === "unsubscribed") &&
              clearActiveFilter()
            }>
            <Dropdown.Item onClick={selectActive}>
              <span>
                Active
                {filterType === "active" && (
                  <span style={{ color: "limegreen", fontWeight: "bold" }}>
                    {"  "}*
                  </span>
                )}
              </span>
            </Dropdown.Item>
            <Dropdown.Item onClick={selectInactive}>
              <span>
                Inactive
                {filterType === "inactive" && (
                  <span style={{ color: "limegreen", fontWeight: "bold" }}>
                    {"  "}*
                  </span>
                )}
              </span>
            </Dropdown.Item>
            <Dropdown.Item disabled={!isFiltered} onClick={clearActiveFilter}>
              <img className='filter-icon' src={filterOff} alt='clear filter' />
              <span
                style={{
                  paddingLeft: "10px",
                  color: isFiltered ? "#212529" : "#a4a5aa",
                }}>
                Clear
              </span>
            </Dropdown.Item>
          </DropdownButton>
        </span>
        <span className='active-header'>
          VERIFIED?
          <DropdownButton
            id='admin-dash-verified-filter-icon'
            title={
              <svg
                width='24'
                height='24'
                viewBox='0 0 42 42'
                fill={
                  isFiltered &&
                  (filterType === "verified" || filterType === "unverified")
                    ? "limegreen"
                    : "#A4A5AA"
                }
                xmlns='http://www.w3.org/2000/svg'>
                <path d='M35 5.25L7 5.25C6.53587 5.25 6.09075 5.43437 5.76256 5.76256C5.43437 6.09075 5.25 6.53587 5.25 7L5.25 11.5325C5.25 12.4478 5.62275 13.3473 6.27025 13.9948L15.75 23.4745L15.75 36.75C15.7503 37.0482 15.8268 37.3413 15.9721 37.6016C16.1174 37.862 16.3267 38.081 16.5803 38.2378C16.8338 38.3946 17.1232 38.4842 17.4211 38.4979C17.7189 38.5116 18.0153 38.4491 18.2822 38.3163L25.2822 34.8163C25.8755 34.5188 26.25 33.9133 26.25 33.25V23.4745L35.7297 13.9948C36.3772 13.3473 36.75 12.4478 36.75 11.5325V7C36.75 6.53587 36.5656 6.09075 36.2374 5.76256C35.9092 5.43437 35.4641 5.25 35 5.25V5.25ZM23.2628 21.5128C23.0999 21.675 22.9708 21.8678 22.8828 22.0802C22.7948 22.2925 22.7497 22.5201 22.75 22.75V32.1685L19.25 33.9185L19.25 22.75C19.2503 22.5201 19.2052 22.2925 19.1172 22.0802C19.0292 21.8678 18.9001 21.675 18.7372 21.5128L8.75 11.5325V8.75L33.2517 8.75L33.2553 11.5203L23.2628 21.5128V21.5128Z' />
              </svg>
            }
            align='end'
            className='three-dots dots-button active-filter'
            onClick={() =>
              (filterType === "active" ||
                filterType === "inactive" ||
                filterType === "subscribed" ||
                filterType === "unsubscribed") &&
              clearActiveFilter()
            }>
            <Dropdown.Item onClick={selectVerified}>
              <span>
                Yes
                {filterType === "verified" && (
                  <span style={{ color: "limegreen", fontWeight: "bold" }}>
                    {"  "}*
                  </span>
                )}
              </span>
            </Dropdown.Item>
            <Dropdown.Item onClick={selectUnverified}>
              <span>
                No
                {filterType === "unverified" && (
                  <span style={{ color: "limegreen", fontWeight: "bold" }}>
                    {"  "}*
                  </span>
                )}
              </span>
            </Dropdown.Item>
            <Dropdown.Item disabled={!isFiltered} onClick={clearActiveFilter}>
              <img className='filter-icon' src={filterOff} alt='clear filter' />
              <span
                style={{
                  paddingLeft: "10px",
                  color: isFiltered ? "#212529" : "#a4a5aa",
                }}>
                Clear
              </span>
            </Dropdown.Item>
          </DropdownButton>
        </span>
        <span className='active-header'>
          SUBSCR?
          <DropdownButton
            id='admin-dash-subscribed-filter-icon'
            title={
              <svg
                width='24'
                height='24'
                viewBox='0 0 42 42'
                fill={
                  isFiltered &&
                  (filterType === "subscribed" || filterType === "unsubscribed")
                    ? "limegreen"
                    : "#A4A5AA"
                }
                xmlns='http://www.w3.org/2000/svg'>
                <path d='M35 5.25L7 5.25C6.53587 5.25 6.09075 5.43437 5.76256 5.76256C5.43437 6.09075 5.25 6.53587 5.25 7L5.25 11.5325C5.25 12.4478 5.62275 13.3473 6.27025 13.9948L15.75 23.4745L15.75 36.75C15.7503 37.0482 15.8268 37.3413 15.9721 37.6016C16.1174 37.862 16.3267 38.081 16.5803 38.2378C16.8338 38.3946 17.1232 38.4842 17.4211 38.4979C17.7189 38.5116 18.0153 38.4491 18.2822 38.3163L25.2822 34.8163C25.8755 34.5188 26.25 33.9133 26.25 33.25V23.4745L35.7297 13.9948C36.3772 13.3473 36.75 12.4478 36.75 11.5325V7C36.75 6.53587 36.5656 6.09075 36.2374 5.76256C35.9092 5.43437 35.4641 5.25 35 5.25V5.25ZM23.2628 21.5128C23.0999 21.675 22.9708 21.8678 22.8828 22.0802C22.7948 22.2925 22.7497 22.5201 22.75 22.75V32.1685L19.25 33.9185L19.25 22.75C19.2503 22.5201 19.2052 22.2925 19.1172 22.0802C19.0292 21.8678 18.9001 21.675 18.7372 21.5128L8.75 11.5325V8.75L33.2517 8.75L33.2553 11.5203L23.2628 21.5128V21.5128Z' />
              </svg>
            }
            align='end'
            className='three-dots dots-button active-filter'
            onClick={() =>
              (filterType === "active" ||
                filterType === "inactive" ||
                filterType === "verified" ||
                filterType === "unverified") &&
              clearActiveFilter()
            }>
            <Dropdown.Item onClick={selectSubscribed}>
              <span>
                Yes
                {filterType === "subscribed" && (
                  <span style={{ color: "limegreen", fontWeight: "bold" }}>
                    {"  "}*
                  </span>
                )}
              </span>
            </Dropdown.Item>
            <Dropdown.Item onClick={selectUnsubscribed}>
              <span>
                No
                {filterType === "unsubscribed" && (
                  <span style={{ color: "limegreen", fontWeight: "bold" }}>
                    {"  "}*
                  </span>
                )}
              </span>
            </Dropdown.Item>
            <Dropdown.Item disabled={!isFiltered} onClick={clearActiveFilter}>
              <img className='filter-icon' src={filterOff} alt='clear filter' />
              <span
                style={{
                  paddingLeft: "10px",
                  color: isFiltered ? "#212529" : "#a4a5aa",
                }}>
                Clear
              </span>
            </Dropdown.Item>
          </DropdownButton>
        </span>
        <span className='text-center'>DELETE</span>
        <span className='text-center'>RUN APP</span>
      </div>
      {userList.map((user, index) => (
        <Card key={index} className='user-table'>
          <Card.Header className='list-group-item'>
            <span id={`admin-dash-user-id-${index + 1}`}>
              <span className='mobile-title'>ID</span>
              {user.id}
            </span>
            <span id={`admin-dash-user-email-${index + 1}`}>
              <span className='mobile-title'>EMAIL</span>
              {user.email}
            </span>
            <span id={`admin-dash-user-full-name-${index + 1}`}>
              <span className='mobile-title'>FULL NAME</span>
              {user.first_name + " " + user.last_name}
            </span>
            <span className='user-active-controls-wrapper'>
              <span className='mobile-title'>ACTIVE?</span>
              <span className='fancy-button-wrapper'>
                {userJustActivated === user.id && (
                  <div className='left-frills frills'></div>
                )}
                <input
                  id={`admin-dash-user-activate-checkbox-${index + 1}`}
                  className='checkbox user-active-checkbox'
                  type='checkbox'
                  onChange={(e) => {
                    setSelUserIdToAct(user.id);
                    setSelUserActFlag(e.target.checked);
                    const userListCopy = userList.map((item) => ({
                      ...item,
                    }));
                    const newUserList = userListCopy.map((obj) => {
                      if (obj.id === user.id) {
                        obj.is_active = e.target.checked;
                        return obj;
                      } else {
                        return obj;
                      }
                    });
                    setUserList(newUserList);
                  }}
                  checked={user.is_active}
                  disabled={
                    (selUserIdToAct && selUserIdToAct !== user.id) ||
                    selUserIdToSub !== null
                  }
                />
                {userJustActivated === user.id && (
                  <div className='right-frills frills'></div>
                )}
              </span>
              <span
                className='user-active-controls'
                style={{
                  visibility: selUserIdToAct === user.id ? "visible" : "hidden",
                }}>
                <img
                  id={`admin-dash-user-activate-cancel-icon-${index + 1}`}
                  src={cancelIcon}
                  onClick={() => {
                    resetUserActiveState(user.id);
                  }}
                />
                <img
                  id={`admin-dash-user-activate-save-icon-${index + 1}`}
                  src={saveIcon}
                  onClick={activateUser}
                />
              </span>
            </span>
            <span
              id={`admin-dash-user-verified-reading-${index + 1}`}
              className='user-active-controls-wrapper user-verified-controls-wrapper'>
              <span className='mobile-title'>VERIFIED?</span>
              {user.is_verified ? "Yes" : "No"}
            </span>
            <span className='user-active-controls-wrapper'>
              <span className='mobile-title'>SUBSCRIBED?</span>
              <span className='fancy-button-wrapper'>
                {userJustSubscribed === user.id && (
                  <div className='left-frills frills'></div>
                )}
                <input
                  id={`admin-dash-user-subscribe-checkbox-${index + 1}`}
                  className='checkbox user-active-checkbox'
                  type='checkbox'
                  onChange={(e) => {
                    setSelUserIdToSub(user.id);
                    setSelUserSubFlag(e.target.checked);
                    const userListCopy = userList.map((item) => ({
                      ...item,
                    }));
                    const newUserList = userListCopy.map((obj) => {
                      if (obj.id === user.id) {
                        obj.is_subscribed = e.target.checked;
                        return obj;
                      } else {
                        return obj;
                      }
                    });
                    setUserList(newUserList);
                  }}
                  checked={user.is_subscribed}
                  disabled={
                    (selUserIdToSub && selUserIdToSub !== user.id) ||
                    selUserIdToAct !== null
                  }
                />
                {userJustSubscribed === user.id && (
                  <div className='right-frills frills'></div>
                )}
              </span>
              <span
                className='user-active-controls'
                style={{
                  visibility: selUserIdToSub === user.id ? "visible" : "hidden",
                }}>
                <img
                  id={`admin-dash-user-subscribe-cancel-checkbox-${index + 1}`}
                  src={cancelIcon}
                  onClick={() => {
                    resetUserSubscribeState(user.id);
                  }}
                />
                <img
                  id={`admin-dash-user-subscribe-save-checkbox-${index + 1}`}
                  src={saveIcon}
                  onClick={subscribeUser}
                />
              </span>
            </span>
            <span className='user-delete-wrapper'>
              <span className='mobile-title'>DELETE</span>
              <img
                id={`admin-dash-user-delete-icon-${index + 1}`}
                src={deleteIcon}
                style={{
                  cursor:
                    (selUserIdToAct && selUserIdToAct !== user.id) ||
                    (selUserIdToSub && selUserIdToSub !== user.id)
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={() => {
                  if (
                    (selUserIdToAct === null && selUserIdToSub === null) ||
                    selUserIdToAct === user.id ||
                    selUserIdToSub === user.id
                  ) {
                    setIsModalDelUser(true);
                    setSelUserIdToDel(user.id);
                    setSelEmailToDel(user.email);
                    setSelUserSubFlagToDel(user.is_subscribed);
                    selUserIdToAct === user.id && resetUserActiveState(user.id);
                    selUserIdToSub === user.id &&
                      resetUserSubscribeState(user.id);
                  }
                }}
              />
            </span>
            <span className='action-btns action-btn-run-app flex-it'>
              <span className='mobile-title'>RUN APP</span>
              <button
                id={`admin-dash-user-app-in-app-icon-${index + 1}`}
                className='btn-small btn-home flex-it'>
                <img
                  src={appIcon}
                  alt='app icon'
                  onClick={(e) => {
                    handleAppClick(
                      e,
                      user.id,
                      user.first_name || user.email,
                      false
                    );
                  }}
                />
              </button>
            </span>
          </Card.Header>
        </Card>
      ))}
      <div className='user-list-stats user-list-stats-desktop'>
        <div>
          Number of users:{" "}
          <span id='admin-dash-footer-no-of-users-desktop'>
            {userList.length}
          </span>
        </div>
        {!isFiltered && searchInput === "" && (
          <>
            <div>
              Number of active users:{" "}
              <span id='admin-dash-footer-no-of-active-users'>
                {initUserList.filter((p) => p.is_active).length}
              </span>
            </div>
            <div>
              Number of verified users:{" "}
              <span id='admin-dash-footer-no-of-verified-users'>
                {initUserList.filter((p) => p.is_verified).length}
              </span>
            </div>
            <div>
              Number of subscribed users:{" "}
              <span id='admin-dash-footer-no-of-subscribed-users'>
                {initUserList.filter((p) => p.is_subscribed).length}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );

  const adminTable = adminList && adminList.length !== 0 && (
    <Fragment>
      <div
        id='user-table-header'
        className='list-group-item list-group-item-admin'>
        <span>ID</span>
        <span>EMAIL</span>
        <span>FULL NAME</span>
        <span className='text-center'>RUN APP</span>
      </div>
      {adminList.map((user, index) => (
        <Card key={index} className='user-table'>
          <Card.Header className='list-group-item list-group-item-admin'>
            <span id={`admin-dash-admin-id-${index + 1}`}>
              <span className='mobile-title'>ID</span>
              {user.id}
            </span>
            <span id={`admin-dash-admin-email-${index + 1}`}>
              <span className='mobile-title'>EMAIL</span>
              {user.email}
            </span>
            <span id={`admin-dash-admin-full-name-${index + 1}`}>
              <span className='mobile-title'>FULL NAME</span>
              {user.first_name + " " + user.last_name}
            </span>
            <span className='action-btns action-btn-run-app flex-it'>
              <span className='mobile-title'>RUN APP</span>
              <button
                id={`admin-dash-admin-app-in-app-icon-${index + 1}`}
                className='btn-small btn-home flex-it'>
                <img
                  src={appIcon}
                  alt='app icon'
                  onClick={(e) => {
                    handleAppClick(
                      e,
                      user.id,
                      user.first_name || user.email,
                      true
                    );
                  }}
                />
              </button>
            </span>
          </Card.Header>
        </Card>
      ))}
      <div className='user-list-stats user-list-stats-desktop'>
        <div>
          Number of admins:{" "}
          <span id='admin-dash-footer-no-of-admins-desktop'>
            {adminList.length}
          </span>
          <div style={{ fontFamily: "'Montserrat EB Italic', sans-serif" }}>
            {" "}
            + YOURSELF OF COURSE :D
          </div>
        </div>
      </div>
    </Fragment>
  );

  const JSXFilterContentMobileModal = (
    <div id='filters-mobile-modal-content'>
      <div id='tabbar' className='row'>
        <div className='col'>
          <label className='radio is-uppercase'>
            <input
              id='admin-dash-mobile-filter-modal-search-by-email-radio-input'
              className='mr-5 my-2 custom-radio'
              type='radio'
              name='searchBy'
              value='email'
              checked={checkedRadio === "email"}
              onChange={(e) => {
                setCheckedRadio(e.target.value);
                setIsUserSearch(true);
                setIsNameSearch(false);
                setSearchInput("");
              }}
            />
            SEARCH BY EMAIL
            <div className='circle'></div>
          </label>
        </div>
        <div className='col'>
          <label className='radio is-uppercase'>
            <input
              id='admin-dash-mobile-filter-modal-search-by-name-radio-input'
              className='mr-5 my-2 custom-radio'
              type='radio'
              name='searchBy'
              value='name'
              checked={checkedRadio === "name"}
              onChange={(e) => {
                setCheckedRadio(e.target.value);
                setIsUserSearch(false);
                setIsNameSearch(true);
                setSearchInput("");
              }}
            />
            SEARCH BY NAME
            <div className='circle'></div>
          </label>
        </div>
      </div>
      <div className='mobile-search-box'>
        <input
          id='admin-dash-mobile-filter-modal-search-text-input'
          className='input search-input search-input-mobile'
          type='text'
          maxLength={20}
          placeholder='Search...'
          autoFocus
          value={searchInput}
          onChange={(e) => searchItems(checkedRadio, e.target.value)}
        />
        <img
          id='admin-dash-mobile-filter-modal-search-text-input-clear-icon'
          className='search-icon'
          src={searchCloseIcon}
          alt={"search users"}
          style={{ visibility: searchInput !== "" ? "visible" : "hidden" }}
          onClick={toggleSearchBox}
        />
      </div>
      <div className='row'>
        <h3>FILTER BY ACTIVE USERS</h3>
      </div>
      <div className='row'>
        <button
          id='admin-dash-mobile-filter-modal-filter-by-active-users-btn'
          className='btn-sort flex-it'
          style={{
            background: filterType === "active" && "limegreen",
            color: filterType === "active" && "#fff",
          }}
          onClick={selectActive}>
          Active
        </button>
        <button
          id='admin-dash-mobile-filter-modal-filter-by-inactive-users-btn'
          className='btn-sort flex-it'
          style={{
            background: filterType === "inactive" && "limegreen",
            color: filterType === "inactive" && "#fff",
          }}
          onClick={selectInactive}>
          Inactive
        </button>
        <button
          id='admin-dash-mobile-filter-modal-filter-by-active-inactive-users-clear-btn'
          className='btn-sort flex-it'
          onClick={clearActiveFilter}>
          Clear
        </button>
      </div>
      <div className='row'>
        <h3>FILTER BY VERIFIED USERS</h3>
      </div>
      <div className='row'>
        <button
          id='admin-dash-mobile-filter-modal-filter-by-verified-users-btn'
          className='btn-sort flex-it'
          style={{
            background: filterType === "verified" && "limegreen",
            color: filterType === "verified" && "#fff",
          }}
          onClick={selectVerified}>
          Verified
        </button>
        <button
          id='admin-dash-mobile-filter-modal-filter-by-unverified-users-btn'
          className='btn-sort flex-it'
          style={{
            background: filterType === "unverified" && "limegreen",
            color: filterType === "unverified" && "#fff",
          }}
          onClick={selectUnverified}>
          Unverified
        </button>
        <button
          id='admin-dash-mobile-filter-modal-filter-by-verified-unverified-users-clear-btn'
          className='btn-sort flex-it'
          onClick={clearActiveFilter}>
          Clear
        </button>
      </div>
      <div className='row'>
        <h3>FILTER BY SUBSCRIBED USERS</h3>
      </div>
      <div className='row'>
        <button
          id='admin-dash-mobile-filter-modal-filter-by-subscribed-users-btn'
          className='btn-sort flex-it'
          style={{
            background: filterType === "subscribed" && "limegreen",
            color: filterType === "subscribed" && "#fff",
          }}
          onClick={selectSubscribed}>
          Subscribed
        </button>
        <button
          id='admin-dash-mobile-filter-modal-filter-by-unsubscribed-users-btn'
          className='btn-sort flex-it'
          style={{
            background: filterType === "unsubscribed" && "limegreen",
            color: filterType === "unsubscribed" && "#fff",
          }}
          onClick={selectUnsubscribed}>
          Unsubscribed
        </button>
        <button
          id='admin-dash-mobile-filter-modal-filter-by-subscribed-unsubscribed-users-clear-btn'
          className='btn-sort flex-it'
          onClick={clearActiveFilter}>
          Clear
        </button>
      </div>
      <div className='row'>
        <h3>SORT BY ID</h3>
      </div>
      <div className='row' style={{ marginBottom: "0" }}>
        <button
          id='admin-dash-mobile-filter-modal-sort-by-id-by-oldest-btn'
          className='btn-sort flex-it'
          style={{
            background: isSorted && "limegreen",
            color: isSorted && "#fff",
          }}
          onClick={() => {
            handleSortToggle(userList, "id", "desc");
            handleSortToggle(initUserList, "id", "desc");
          }}>
          Oldest First
        </button>
        <button
          id='admin-dash-mobile-filter-modal-sort-by-id-by-newest-btn'
          className='btn-sort flex-it'
          style={{
            background: !isSorted && "limegreen",
            color: !isSorted && "#fff",
          }}
          onClick={() => {
            handleSortToggle(userList, "id", "asc");
            handleSortToggle(initUserList, "id", "asc");
          }}>
          Newest First
        </button>
        <div className='result-count'>
          {(isFiltered || searchInput !== "") && (
            <span>
              Number of results:{" "}
              <span
                id='admin-dash-mobile-filter-modal-no-of-results'
                style={{ fontWeight: "bold" }}>
                {userList.length}
              </span>
            </span>
          )}
        </div>
      </div>
    </div>
  );

  return fetchErr ? (
    <div className='fail-message' style={{ marginTop: "3rem" }}>
      Failed to get user data. Please refresh page to try again.
    </div>
  ) : (
    <Fragment>
      {(initUserList.length !== 0 || adminList.length !== 0) && (
        <div
          id='admin-console-wrapper'
          className={`admin-console-wrapper ${isApp && "flex-it"}`}>
          {isApp && (
            <div
              id='admin-dash-app-in-app-back-to-users-btn'
              className='user-back-btn user-back-btn-users flex-it'
              onClick={handleAppBack}>
              <img src={backArrow} alt='back' />
              <span className='back-to-list'>BACK TO USERS</span>
              <span className='user-icon-mobile'>
                <img src={userIcon} alt='user icon' />
              </span>
            </div>
          )}
          <h1 className='admin-console-title'>
            Admin Console{" "}
            <span id='admin-dash-sub-title' className='admin-console-sub-title'>
              {" ~ "}
              {isApp
                ? "APP-IN-APP"
                : viewType === "user"
                ? "USER TABLE"
                : "ADMIN TABLE"}
            </span>
          </h1>
          {isApp && (
            <div className='admin-console-user-info'>
              {isAccount ? (
                <div
                  id='admin-dash-app-in-app-back-to-app-btn'
                  className='user-back-btn user-back-btn-account flex-it'
                  onClick={() => setIsAccount(false)}>
                  <img className='app-img' src={appIcon} alt='back' />
                  <span className='back-to-list'>BACK TO APP</span>
                </div>
              ) : (
                <div className='user-panel'>
                  <img
                    className='icon my-1 mx-2 svg_embedded_img'
                    height='21'
                    src={AccountIcon}
                    alt='account icon'
                  />
                  <span
                    id='admin-dash-app-in-app-user-panel-name'
                    className='user-panel-name'
                    onClick={() => setIsAccount(true)}>
                    {selEmailToRunApp}
                  </span>
                </div>
              )}
            </div>
          )}
          {!isApp && (
            <div className='filter-controls-mobile'>
              <div
                style={{
                  visibility:
                    isApp || viewType === "admin" || initUserList.length === 0
                      ? "hidden"
                      : "visible",
                }}>
                <span
                  id='admin-dash-mobile-filter-active-green-dot'
                  className='mobile-filter-dot'
                  style={{
                    visibility:
                      !isApp &&
                      viewType === "user" &&
                      (isFiltered || searchInput !== "" || !isSorted) &&
                      initUserList.length !== 0
                        ? "visible"
                        : "hidden",
                  }}>
                  .{" "}
                </span>
                <div
                  id='admin-dash-mobile-filters-btn'
                  onClick={() => (
                    setShowMobileModal(true), cloneFilterState()
                  )}>
                  Filters
                  <img
                    className='icon my-1 mx-2 svg_embedded_img filter-controls-mobile-arrow-down'
                    src={arrowDown}
                    alt='arrow down'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <SwitchTransition mode='out-in'>
        <CSSTransition
          key={isApp ? transitionStateApp : null}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          onExit={(node) => {
            node.style.position = "fixed";
            node.style.top = -1 * window.scrollY + "px";
          }}
          classNames='slide'>
          {!transitionStateApp ? (
            <Fragment>
              <div
                id='user-list'
                className={`admin-dash-wrapper ${!isApp && "flex-it"}`}
                style={{
                  height:
                    initUserList.length === 0 && adminList.length === 0
                      ? "calc(100% - 80px)"
                      : "auto",
                }}>
                <div
                  className='change-view-title'
                  // style={{ display: (isConfig || isDashboard) && "none" }}
                >
                  CHANGE TABLE
                </div>
                <Tabs
                  id='admin-dash-tabbar'
                  activeKey={viewType}
                  onSelect={(k) => {
                    setViewType(k);
                  }}>
                  <Tab
                    eventKey='user'
                    title={
                      <span
                        className={`admin-tab-icon ${
                          viewType === "user" ? "active" : ""
                        }`}>
                        <img src={nonStaffIcon} alt='user icon' />
                      </span>
                    }>
                    <div>{userTable || authMsg}</div>
                    <Modal
                      title='Delete User'
                      content={JSXContentDelUser}
                      isModal={isModalDelUser}
                      handleCloseModal={handleCloseModalDelUser}
                      actionName='Delete'
                      actionFn={deleteUser}
                      loading={delLoading}
                      error={delError}
                      success={delSuccess}
                      errMsg={delErrMsg}
                      actionBtnId='admin-dash-del-user-modal-action-btn'
                      cancelBtnId='admin-dash-del-user-modal-cancel-btn'
                    />
                    <MobileModal
                      showMobileModal={showMobileModal}
                      setShowMobileModal={setShowMobileModal}
                      title='Sort and Filters'
                      content={JSXFilterContentMobileModal}
                      actionFn={handleSubmitFilters}
                      actionName='Show'
                      handleCloseModal={handleClearAllFilters}
                      cancelName='Clear All'
                      cancelFlag={true}
                      cancelFilters={cancelFilters}
                      actionBtnId='admin-dash-mobile-filter-modal-action-btn'
                      cancelBtnId='admin-dash-mobile-filter-modal-cancel-btn'
                    />
                  </Tab>
                  <Tab
                    eventKey='admin'
                    title={
                      <span
                        className={`admin-tab-icon ${
                          viewType === "admin" ? "active" : ""
                        }`}>
                        <img
                          className='filter-icon'
                          src={staffIcon}
                          alt='admin icon'
                        />
                      </span>
                    }>
                    <div>{adminTable || authMsg}</div>
                  </Tab>
                </Tabs>
                {adminList &&
                  adminList.length !== 0 &&
                  (viewType === "admin" ? (
                    <div className='user-list-stats user-list-stats-mobile'>
                      <div>
                        Number of admins:{" "}
                        <span id='admin-dash-footer-no-of-admins-mobile'>
                          {adminList.length}
                        </span>
                        <div
                          style={{
                            fontFamily: "'Montserrat EB Italic', sans-serif",
                          }}>
                          {" "}
                          + YOURSELF OF COURSE :D
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='user-list-stats user-list-stats-mobile'>
                      <div>
                        Number of users:{" "}
                        <span id='admin-dash-footer-no-of-users-mobile'>
                          {userList.length}
                        </span>
                      </div>
                      {!isFiltered && searchInput === "" && (
                        <>
                          <div>
                            Number of active users:{" "}
                            <span id='admin-dash-footer-no-of-active-users-mobile'>
                              {initUserList.filter((p) => p.is_active).length}
                            </span>
                          </div>
                          <div>
                            Number of verified users:{" "}
                            <span id='admin-dash-footer-no-of-verified-users-mobile'>
                              {initUserList.filter((p) => p.is_verified).length}
                            </span>
                          </div>
                          <div>
                            Number of subscribed users:{" "}
                            <span id='admin-dash-footer-no-of-subscribed-users-mobile'>
                              {
                                initUserList.filter((p) => p.is_subscribed)
                                  .length
                              }
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </Fragment>
          ) : (
            <div className='app-in-app-wrapper'>
              {isAccount ? (
                <Account
                  userIdAdminOverride={selUserIdToRunApp}
                  isAdminOverride={isAdminToRunApp}
                  adminEmail={adminEmail}
                  isMobile={props.isMobile}
                  loaded={props.loaded}
                  setSelEmailToRunApp={setSelEmailToRunApp}
                />
              ) : (
                <Home
                  userIdAdminOverride={selUserIdToRunApp}
                  isTablet={props.isTablet}
                  isMobile={props.isMobile}
                  loaded={props.loaded}
                />
              )}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </Fragment>
  );
};

export default AdminDash;

import React, { useState, useEffect, Fragment } from "react";
import DashboardCard from "./DashboardCard";
import InstantDataWrapper from "./InstantDataWrapper";
import HistoricalDataWrapper from "./HistoricalDataWrapper";
import CumulativeDataWrapper from "./CumulativeDataWrapper";
import DateTime from "./DateTime";
import backArrow from "../../images/home_back_arrow.svg";
import dashboardMax from "../../images/dashboard_max.svg";
import dashboardMin from "../../images/dashboard_min.svg";
import phase1Icon from "./PhaseCard/phase1.svg";
import phase2Icon from "./PhaseCard/phase2.svg";
import phase3Icon from "./PhaseCard/phase3.svg";
import homeIcon from "../../images/navbar_home_icon.svg";
import baseURL from "../../baseURL";
import "./Dashboard.css";

const Dashboard = (props) => {
  const [powerIsFullscreen, setPowerIsFullscreen] = useState(false);
  const [energyIsFullscreen, setEnergyIsFullscreen] = useState(false);
  const [powerChartData, setPowerChartData] = useState(null);
  const [energyChartData, setEnergyChartData] = useState(null);
  const [energyCumData, setEnergyCumData] = useState(null);
  const [now, setNow] = useState(Date.now());
  const [donutList, setDonutList] = useState({});
  const [WSMessage, setWSMessage] = useState([]);
  const [retryWSConn, setRetryWSConn] = useState(false);
  const [deviceIsOnline, setDeviceIsOnline] = useState(false);
  const [activePower, setActivePower] = useState("");
  const [powerPhaseData, setPowerPhaseData] = useState([]);
  const [powerChartLiveData, setPowerChartLiveData] = useState([]);
  const [energyChartLiveData, setEnergyChartLiveData] = useState([]);

  useEffect(() => {
    sessionStorage.setItem("powerInterval", "W");
    sessionStorage.setItem("energyInterval", "W");
    sessionStorage.setItem("energyGroup", "day");
    sessionStorage.setItem("powerLegendImport", true);
    sessionStorage.setItem("powerLegendExport", true);
    sessionStorage.setItem("powerLegendPV", true);
    sessionStorage.setItem("powerBGFill", false);
    sessionStorage.setItem("energyLegendImport", true);
    sessionStorage.setItem("energyLegendExport", true);
    sessionStorage.setItem("energyLegendPV", true);

    return () => {
      sessionStorage.removeItem("powerInterval");
      sessionStorage.removeItem("energyInterval");
      sessionStorage.removeItem("energyGroup");
      sessionStorage.removeItem("powerLegendImport");
      sessionStorage.removeItem("powerLegendExport");
      sessionStorage.removeItem("powerLegendPV");
      sessionStorage.removeItem("powerBGFill");
      sessionStorage.removeItem("energyLegendImport");
      sessionStorage.removeItem("energyLegendExport");
      sessionStorage.removeItem("energyLegendPV");
    };
  }, [props.isDashboard]);

  useEffect(() => {
    props.setAnimExit(false);
  }, []);

  // Power data request
  const fetchPowerData = (start, end) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    const deviceId = props.selectedConfigDeviceData.id;
    const head = new Headers();
    head.append("Authorization", "Bearer " + token);

    const url = new URL(
      props.dashboardType === "ZEE"
        ? `./api/v1/devices/${deviceId}/watts?from=${start}&to=${end}`
        : `./api/v1/smartmeters/${deviceId}/watts?from=${start}&to=${end}`,
      baseURL
    );
    fetch(url, {
      method: "GET",
      headers: head,
    })
      .then((response) => {
        if (response.status == 401) {
          console.log(response.status);
          props.handleDashboardBack();
          props.getDeviceList();
        }
        if (!response.ok) {
          throw new Error("Failed to fetch.");
        }
        return response.json();
      })
      .then((data) => {
        setPowerChartData(data);
        setNow(Date.now());
      })
      .catch((error) => {
        console.log(error);
        // setPowerChartData([]);
      });
  };

  // Energy data request
  const fetchEnergyData = (start, end) => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    const deviceId = props.selectedConfigDeviceData.id;
    const head = new Headers();
    head.append("Authorization", "Bearer " + token);

    const url = new URL(
      props.dashboardType === "ZEE"
        ? `./api/v1/devices/${deviceId}/energy?from=${start}&to=${end}`
        : `./api/v1/smartmeters/${deviceId}/energy?from=${start}&to=${end}`,
      baseURL
    );
    fetch(url, {
      method: "GET",
      headers: head,
    })
      .then((response) => {
        if (response.status == 401) {
          props.handleDashboardBack();
          props.getDeviceList();
        }
        if (!response.ok) {
          throw new Error("Failed to fetch.");
        }
        return response.json();
      })
      .then((data) => {
        setEnergyChartData(data);
        setNow(Date.now());
      })
      .catch((error) => {
        console.log(error);
        // setEnergyChartData([]);
      });
  };

  useEffect(() => {
    let today = new Date();
    let todayEpoch = parseInt(today / 1000);
    let oneWeekBefore = new Date(new Date().setDate(today.getDate() - 6));
    let oneWeekBeforeEpoch = parseInt(oneWeekBefore / 1000);
    fetchPowerData(oneWeekBeforeEpoch, todayEpoch);
    fetchEnergyData(oneWeekBeforeEpoch, todayEpoch);
    console.log("<Dashboard /> rendered!");
  }, []);

  useEffect(() => {
    if (
      sessionStorage.getItem("powerInterval") == "Live" &&
      sessionStorage.getItem("energyInterval") == "Live" &&
      !powerIsFullscreen &&
      !energyIsFullscreen
    ) {
      let today = new Date();
      let todayEpoch = parseInt(today / 1000);
      let intervalEnd = new Date(new Date().setHours(today.getHours() - 1));
      let intervalEndEpoch = parseInt(intervalEnd / 1000);
      fetchPowerData(intervalEndEpoch, todayEpoch);
    }
    // Special fix for energy live chart resetting to original interval on switching fs mode
    if (sessionStorage.getItem("energyInterval") == "Live") {
      let today = new Date();
      let todayEpoch = parseInt(today / 1000);
      let intervalEnd = new Date(new Date().setHours(today.getHours() - 1));
      let intervalEndEpoch = parseInt(intervalEnd / 1000);
      fetchEnergyData(intervalEndEpoch, todayEpoch);
    }
  }, [energyIsFullscreen]);

  useEffect(() => {
    if (
      sessionStorage.getItem("powerInterval") == "Live" &&
      sessionStorage.getItem("energyInterval") == "Live" &&
      !powerIsFullscreen &&
      !energyIsFullscreen
    ) {
      let today = new Date();
      let todayEpoch = parseInt(today / 1000);
      let intervalEnd = new Date(new Date().setHours(today.getHours() - 1));
      let intervalEndEpoch = parseInt(intervalEnd / 1000);
      fetchEnergyData(intervalEndEpoch, todayEpoch);
    }
  }, [powerIsFullscreen]);

  // Cumulative Data Wrapper request
  useEffect(() => {
    const fetchData = () => {
      const token =
        localStorage.getItem("token") || sessionStorage.getItem("token");
      const deviceId = props.selectedConfigDeviceData.id;
      const head = new Headers();
      head.append("Authorization", "Bearer " + token);
      const url = new URL(
        props.dashboardType === "ZEE"
          ? `./api/v1/devices/${deviceId}/cumulative`
          : `./api/v1/smartmeters/${deviceId}/cumulative`,
        baseURL
      );

      let offset = (new Date().getTimezoneOffset() * -1) / 60;
      url.searchParams.append("timezone", offset);

      fetch(url, {
        method: "GET",
        headers: head,
      })
        .then((response) => {
          if (response.status == 401) {
            props.handleDashboardBack();
            props.getDeviceList();
          }
          if (!response.ok) {
            throw new Error("Failed to fetch.");
          }
          return response.json();
        })
        .then((data) => {
          setEnergyCumData(data);
          const obj = {};
          Object.entries(data)
            .slice(2, 6)
            .map((entry) => {
              obj[entry[0]] = entry[1];
            });
          setDonutList(obj);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchData();
    let interval = setInterval(fetchData, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Live Data request
  useEffect(() => {
    const token =
      localStorage.getItem("token") || sessionStorage.getItem("token");

    // Open the WebSocket connection using the JWT.
    let ws;

    const openWsConnection = (jwtAuth) => {
      // If a connection already exists, close it.
      ws && ws.close();

      let locationHost =
        !process.env.NODE_ENV || process.env.NODE_ENV === "development"
          ? baseURL.replace(/^https?:\/\//, "")
          : baseURL.host;

      ws = new WebSocket(
        `${
          window.location.protocol === "http:" ? "ws" : "wss"
        }://${locationHost}/ws/devices/?token=${jwtAuth}`
      );

      const deviceId = props.selectedConfigDeviceData.id;
      console.log(deviceId);
      let deviceIdArr = [deviceId];

      let online = false;
      let isFirstRun = true;

      let subscribeMsg = {
        type: "subscribe",
        devices: props.dashboardType === "ZEE" ? deviceIdArr : [],
        smartmeters: props.dashboardType === "SM" ? deviceIdArr : [],
      };

      let heartbeatMsg = {
        type: "heartbeat",
        devices: props.dashboardType === "ZEE" ? deviceIdArr : [],
        smartmeters: props.dashboardType === "SM" ? deviceIdArr : [],
      };

      // Send a message whenever the WebSocket connection opens.
      let openWS = (ws.onopen = (event) => {
        console.log("WebSocket connection is opened.");
        online = false;

        if (isFirstRun) {
          // Send subscribe message
          ws.send(JSON.stringify(subscribeMsg));
          // Send heartbeat message
          setTimeout(() => {
            ws.send(JSON.stringify(heartbeatMsg));
          }, 1000);
          setTimeout(() => {
            setDeviceIsOnline(online);
            // 3 sec wait span + 1sec send heartbeat delay
          }, 4000);
          isFirstRun = false;
        } else {
          // Send heartbeat message
          ws.send(JSON.stringify(heartbeatMsg));
          // If websocket is open but device did not respond for 5s, then it's offline
          setTimeout(() => {
            setDeviceIsOnline(online);
          }, 5000);
        }
      });

      let sendData = !props.closeDashboardWS && setInterval(openWS, 60000);

      // Display any new messages received in the `messageDiv`.
      ws.onmessage = (event) => {
        console.log("WebSocket message received: ", event.data);

        let dataObj = JSON.parse(event.data);
        // Check if the device is online, else if device just returned after offline sedn a heartbeat to check
        let resDeviceId =
          props.dashboardType === "ZEE" ? dataObj.Device : dataObj.SM;

        if (dataObj.type === "subscribe") {
          if (resDeviceId !== online[0]) {
            openWS();
          }
          let resMessage = dataObj.message;
          console.log(resMessage);
          setWSMessage(resMessage);
          setActivePower(resMessage.M_AC_P);
          const l1Obj = {
            v: Number(resMessage.M_AC_U1),
            a: Number(resMessage.M_AC_I1),
            p: Number(resMessage.M_AC_P1),
            q: Number(resMessage.M_AC_S1),
            pf: Number(resMessage.M_AC_PF_COSPHI1).toFixed(3),
          };
          const l2Obj = {
            v: Number(resMessage.M_AC_U2),
            a: Number(resMessage.M_AC_I2),
            p: Number(resMessage.M_AC_P2),
            q: Number(resMessage.M_AC_S2),
            pf: Number(resMessage.M_AC_PF_COSPHI2).toFixed(3),
          };
          const l3Obj = {
            v: Number(resMessage.M_AC_U3),
            a: Number(resMessage.M_AC_I3),
            p: Number(resMessage.M_AC_P3),
            q: Number(resMessage.M_AC_S3),
            pf: Number(resMessage.M_AC_PF_COSPHI3).toFixed(3),
          };

          let livePowerImportExport = Number(resMessage.M_AC_P);
          let livePowerPVOutput = Number(resMessage.M_AC_P_PV);
          let powerLive;
          let liveEnergyImport = Number(resMessage.M_AC_E_IMP);
          let liveEnergyExport = Number(resMessage.M_AC_E_EXP);
          let liveEnergyPVOutput = Number(resMessage.M_AC_E_PV);
          let energyLive;

          if (props.dashboardType === "ZEE") {
            powerLive = {
              date: new Date(),
              import: livePowerImportExport > 0 ? livePowerImportExport : 0,
              export:
                livePowerImportExport < 0 ? livePowerImportExport * -1 : 0,
              pv: livePowerPVOutput,
            };
            energyLive = {
              date: new Date(),
              import: liveEnergyImport,
              export: liveEnergyExport,
              pv: liveEnergyPVOutput,
            };
          } else {
            powerLive = {
              date: new Date(),
              import: livePowerImportExport > 0 ? livePowerImportExport : 0,
              export:
                livePowerImportExport < 0 ? livePowerImportExport * -1 : 0,
            };
            energyLive = {
              date: new Date(),
              import: liveEnergyImport,
              export: liveEnergyExport,
            };
          }

          setPowerPhaseData([l1Obj, l2Obj, l3Obj]);
          setPowerChartLiveData(powerLive);
          setEnergyChartLiveData(energyLive);
        }
        if (dataObj.type === "heartbeat") {
          online = [deviceId];
          setDeviceIsOnline(online);
        }
      };

      ws.onerror = (event) => {
        console.log("WebSocket error received: ", event);
        setTimeout(() => {
          setRetryWSConn(!retryWSConn);
        }, 1000);
      };

      ws.onclose = (event) => {
        console.log("WebSocket connection closed.");
        setWSMessage([]);
        setRetryWSConn(true);
        clearInterval(sendData);
      };
    };

    !props.closeWS && openWsConnection(token);
    return () => ws && ws.close();
  }, [props.selectedConfigDeviceData, retryWSConn, props.closeWS]);

  const JSXPowerChart = (
    <Fragment>
      <DashboardCard
        classNames={`full-height-card ${powerIsFullscreen && "fullscreen"}`}
        title={`${props.dashboardType} Power Chart`}
        content={
          <HistoricalDataWrapper
            mode='power'
            dashboardType={props.dashboardType}
            now={now}
            data={powerChartData}
            fetchPowerData={fetchPowerData}
            fs={powerIsFullscreen}
            isMobile={props.isMobile}
            isTablet={props.isTablet}
            setShowMobileModalRS={props.setShowMobileModalRS}
            startDateEpoch={props.startDateEpoch}
            endDateEpoch={props.endDateEpoch}
            rangeSelModalFlag={props.rangeSelModalFlag}
            setRangeSelModalFlag={props.setRangeSelModalFlag}
            powerChartLiveData={powerChartLiveData}
          />
        }
        actionIcon={
          <img
            id={`dash-power-chart-${
              powerIsFullscreen ? "minimize" : "maximize"
            }-icon`}
            src={powerIsFullscreen ? dashboardMin : dashboardMax}
          />
        }
        fs={powerIsFullscreen}
        setter={setPowerIsFullscreen}
      />
    </Fragment>
  );

  const JSXEnergyChart = (
    <Fragment>
      <DashboardCard
        classNames={`full-height-card ${energyIsFullscreen && "fullscreen"}`}
        title={`${props.dashboardType} Energy Chart`}
        content={
          <HistoricalDataWrapper
            mode='energy'
            dashboardType={props.dashboardType}
            now={now}
            data={energyChartData}
            fetchEnergyData={fetchEnergyData}
            fs={energyIsFullscreen}
            isMobile={props.isMobile}
            isTablet={props.isTablet}
            setShowMobileModalRS={props.setShowMobileModalRS}
            startDateEpoch={props.startDateEpoch}
            endDateEpoch={props.endDateEpoch}
            rangeSelModalFlag={props.rangeSelModalFlag}
            setRangeSelModalFlag={props.setRangeSelModalFlag}
            energyChartLiveData={energyChartLiveData}
          />
        }
        actionIcon={
          <img
            id={`dash-energy-chart-${
              energyIsFullscreen ? "minimize" : "maximize"
            }-icon`}
            src={energyIsFullscreen ? dashboardMin : dashboardMax}
          />
        }
        fs={energyIsFullscreen}
        setter={setEnergyIsFullscreen}
      />
    </Fragment>
  );

  const JSXLocalTime = (
    <Fragment>
      <DashboardCard
        classNames='half-height-card'
        title='Local Time'
        content={<DateTime />}
      />
    </Fragment>
  );

  const JSXLoadNow = (
    <Fragment>
      <DashboardCard
        classNames='half-height-card'
        title='Load Now'
        content={
          <InstantDataWrapper
            mode='totalPower'
            status={powerPhaseData}
            activePower={activePower}
            deviceIsOnline={deviceIsOnline}
          />
        }
      />
    </Fragment>
  );

  const JSXPhaseCard = (phaseIndex) => {
    return (
      <Fragment>
        <DashboardCard
          classNames='full-height-card'
          title={`Phase L${phaseIndex}`}
          content={
            <InstantDataWrapper
              mode='phasePower'
              phaseIndex={phaseIndex - 1}
              status={powerPhaseData}
              activePower={activePower}
              deviceIsOnline={deviceIsOnline}
            />
          }
          displayIcon={
            <img
              src={
                phaseIndex === 1
                  ? phase1Icon
                  : phaseIndex === 2
                  ? phase2Icon
                  : phaseIndex === 3
                  ? phase3Icon
                  : null
              }
            />
          }
        />
      </Fragment>
    );
  };

  const JSXEnergyCard = (mode) => {
    return (
      <Fragment>
        <DashboardCard
          classNames='half-height-card'
          title={`Energy ${
            mode === "today"
              ? "Today"
              : mode === "yesterday"
              ? "Yesterday"
              : mode === "week"
              ? "Last Week"
              : mode === "month"
              ? "Last Month"
              : mode === "year"
              ? "Last Year"
              : mode === "total"
              ? "ALL"
              : ""
          }`}
          content={
            <CumulativeDataWrapper
              mode={mode}
              dashboardType={props.dashboardType}
              energyCumData={energyCumData}
              donutList={donutList}
            />
          }
        />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div id='config-page'>
        <div className='config-page-title'>
          <div
            id='dash-back-to-list-btn'
            className='config-back-btn dash-back-btn flex-it'
            onClick={props.handleDashboardBack}>
            <img src={backArrow} alt='back' />
            <span className='back-to-list'>BACK TO LIST</span>
            <span className='home-icon-mobile'>
              <img src={homeIcon} alt='home icon' />
            </span>
          </div>
          <span id='dash-sub-title'>
            {props.selectedConfigDeviceData.name} Dashboard
          </span>
          <span className='device-status-global'>
            <span
              id='dash-device-status-dot'
              className='direction-dot'
              style={{
                position: "absolute",
                top: "6px",
                width: "14px",
                height: "14px",
                borderRadius: "50%",
                backgroundColor: deviceIsOnline ? "aquamarine" : "#E44646",
              }}></span>
            <span
              id='dash-device-status-text'
              className='device-status-text'
              style={{
                fontSize: "11px",
                minWidth: "60px",
                color: deviceIsOnline ? "#efefef" : "#9e9e9e",
              }}>
              {deviceIsOnline ? "ONLINE" : "OFFLINE"}
            </span>
          </span>
        </div>
        <div className='dashboard-page-content'>
          {powerIsFullscreen ? (
            // Fullscreen Power chart
            <div className='columns fullscreen'>
              <div className='column is-full'>{JSXPowerChart}</div>
            </div>
          ) : energyIsFullscreen ? (
            // Fullscreen Energy chart
            <div className='columns fullscreen'>
              <div className='column is-full'>{JSXEnergyChart}</div>
            </div>
          ) : !props.isMobile && !props.isTablet ? (
            // Desktop View (1920px ~ 1366px)
            <Fragment>
              <div className='columns'>
                <div className='column is-one-third'>{JSXPowerChart}</div>
                <div className='column half-height-wrapper'>
                  {JSXLocalTime}
                  {JSXLoadNow}
                </div>
                <div className='column'>{JSXPhaseCard(1)}</div>
                <div className='column'>{JSXPhaseCard(2)}</div>
                <div className='column'>{JSXPhaseCard(3)}</div>
              </div>

              <div className='columns columns-last'>
                <div className='column is-one-third'>{JSXEnergyChart}</div>
                <div className='column half-height-wrapper'>
                  {JSXEnergyCard("today")}
                  {JSXEnergyCard("month")}
                </div>
                <div className='column half-height-wrapper'>
                  {JSXEnergyCard("yesterday")}
                  {JSXEnergyCard("year")}
                </div>
                <div className='column half-height-wrapper'>
                  {JSXEnergyCard("week")}
                  {JSXEnergyCard("total")}
                </div>
              </div>
            </Fragment>
          ) : props.isTablet && !props.isMobile ? (
            // Tablet View (1365px ~ 769px)
            <Fragment>
              <div className='columns'>
                <div className='column half-height-wrapper'>
                  {JSXLocalTime}
                  {JSXLoadNow}
                </div>
                <div className='column'>{JSXPhaseCard(1)}</div>
                <div className='column'>{JSXPhaseCard(2)}</div>
                <div className='column'>{JSXPhaseCard(3)}</div>
              </div>

              <div className='columns'>
                <div className='column half-height-wrapper'>
                  {JSXEnergyCard("today")}
                  {JSXEnergyCard("month")}
                </div>
                <div className='column half-height-wrapper'>
                  {JSXEnergyCard("yesterday")}
                  {JSXEnergyCard("year")}
                </div>
                <div className='column half-height-wrapper'>
                  {JSXEnergyCard("week")}
                  {JSXEnergyCard("total")}
                </div>
              </div>

              <div className='columns columns-last'>
                <div className='column is-half'>{JSXPowerChart}</div>
                <div className='column is-half'>{JSXEnergyChart}</div>
              </div>
            </Fragment>
          ) : props.isMobile && !props.isTablet ? (
            // Mobile View (768px ~ 360px)
            <Fragment>
              <div className='columns is-mobile'>
                <div className='column is-half'>{JSXLocalTime}</div>
                <div className='column is-half'>{JSXLoadNow}</div>
              </div>

              <div
                className='columns is-mobile full-column-mobile'
                id='phase-cards-wrapper'>
                <div className='column is-one-third fullwidth-column-mobile'>
                  {JSXPhaseCard(1)}
                </div>
                <div className='column is-one-third fullwidth-column-mobile'>
                  {JSXPhaseCard(2)}
                </div>
                <div className='column is-one-third fullwidth-column-mobile'>
                  {JSXPhaseCard(3)}
                </div>
              </div>

              <div className='columns half-height-wrapper'>
                <div className='column'>{JSXPowerChart}</div>
                <div className='column'>{JSXEnergyChart}</div>
              </div>

              <div className='columns is-mobile full-column-mobile'>
                <div className='column is-half fullwidth-column-mobile'>
                  {JSXEnergyCard("today")}
                </div>
                <div className='column is-half fullwidth-column-mobile'>
                  {JSXEnergyCard("yesterday")}
                </div>
              </div>

              <div className='columns is-mobile full-column-mobile'>
                <div className='column is-half fullwidth-column-mobile'>
                  {JSXEnergyCard("week")}
                </div>
                <div className='column is-half fullwidth-column-mobile'>
                  {JSXEnergyCard("month")}
                </div>
              </div>

              <div className='columns is-mobile full-column-mobile columns-last'>
                <div className='column is-half fullwidth-column-mobile'>
                  {JSXEnergyCard("year")}
                </div>
                <div className='column is-half fullwidth-column-mobile'>
                  {JSXEnergyCard("total")}
                </div>
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import SmartZeeLogo from "../../images/smartzee_logo.svg";
import HomeIcon from "../../images/navbar_home_icon.svg";
import AccountIcon from "../../images/navbar_account_icon.svg";
import logoutIcon from "../../images/logout_icon.svg";
import mobileMenuHamburgerIcon from "../../images/mobile_menu_hamburger.svg";
import mobileMenuCloseIcon from "../../images/mobile_menu_close.svg";
import baseURL from "../../baseURL";
import BaseButton from "../BaseButton/BaseButton";
import "./Navbar.css";

const Navbar = (props) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [active, setactive] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [loadingLogout, setLoadingLogout] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const userId =
    localStorage.getItem("userId") || sessionStorage.getItem("userId");

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      const head = new Headers();
      head.append("Authorization", "Bearer " + token);

      const url = new URL("./api/v1/user/me", baseURL);

      const response = await fetch(url, {
        method: "GET",
        headers: head,
      });
      if (response.ok) {
        let data = await response.json();
        setDisplayName(data.first_name || data.email);
        props.setUserEmail(data.email);
        props.setUserIsVerified(data.is_verified);
        props.setUserIsSubscribed(data.is_subscribed);
        props.setLoaded(false);
      } else {
        if (response.status === 403) {
          props.setUserIsVerified(false);
        } else {
          props.setUserUnauthorized(true);
        }
      }
    } catch (error) {
      props.setLoaded(false);
      console.log(error);
    }
  };

  const handleLogoutSubmit = (e) => {
    setLoadingLogout(true);
    setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userId");
      props.setToken("");
      props.setAuthenticated(false);
      history.push("/");
    }, 2000);
  };

  const closeNav = (tab) => {
    setNavbarOpen(false);
    if (tab === "home") {
      setactive("home");
    } else if (tab === "account") {
      setactive("account");
    }
  };

  const openNav = () => {
    setNavbarOpen(true);
  };

  return (
    <div className='navbar'>
      <div className='navbar-left'>
        <div className='sidenav-wrapper level-left has-text-centered'>
          <div
            id='sidebar'
            className={"sidenav " + (navbarOpen ? "opened" : "")}>
            <img
              id='sidenav-zee-logo'
              width='100'
              src={SmartZeeLogo}
              alt='logo'
            />
            <a
              id='navbar-mobile-sidenav-close-btn'
              className='closebtn'
              onClick={() => closeNav(null)}>
              <img
                src={mobileMenuCloseIcon}
                alt='close icon'
                style={{ width: "55px" }}
              />
            </a>
            <div
              className={active === "home" ? "menu_item active" : "menu_item"}>
              <NavLink
                id='navbar-mobile-sidenav-home-link'
                to='/'
                exact
                onClick={() => closeNav("home")}>
                <img
                  className='icon my-1 mx-2 svg_embedded_img'
                  height='21'
                  src={HomeIcon}
                  alt='home icon'
                />
                HOME
              </NavLink>
            </div>
            <div
              className={
                active === "account" ? "menu_item active" : "menu_item"
              }>
              <NavLink
                id='navbar-mobile-sidenav-account-link'
                to='/account'
                exact
                onClick={() => closeNav("account")}>
                <img
                  className='icon my-1 mx-2 svg_embedded_img'
                  height='21'
                  src={AccountIcon}
                  alt='account icon'
                />
                ACCOUNT
              </NavLink>
            </div>
            <div className='side-footer has-text-centered'>
              <span>Copyrights &copy; {new Date().getFullYear()}</span>
              <span>
                <a
                  href='https://smartzee.com/'
                  target='_blank'
                  style={{ textDecoration: "none" }}>
                  SmartZee
                </a>
                &nbsp; Germany
              </span>
              <span>All rights reserved.</span>
            </div>
          </div>
          <div className='level is-mobile'>
            <span
              id='navbar-mobile-sidebar-icon'
              className='level-item has-text-centered ml-4 '
              style={{ cursor: "pointer" }}
              onClick={() => openNav()}>
              <img
                className='menu-icon'
                src={mobileMenuHamburgerIcon}
                alt='menu icon'
              />
            </span>
          </div>
        </div>
        <div
          id={location.pathname === "/account" && "user-panel-hidden"}
          className='user-panel'>
          <img
            className='icon my-1 mx-2 svg_embedded_img'
            height='21'
            src={AccountIcon}
            alt='account icon'
          />
          <span
            id='navbar-user-panel'
            className='user-panel-name'
            onClick={() => history.push("/account")}>
            {displayName}
          </span>
          <BaseButton
            id='navbar-logout-btn'
            classes='user-panel-logout'
            loading={loadingLogout}
            display={true}
            displayIcon={logoutIcon}
            onClick={handleLogoutSubmit}
          />
        </div>
      </div>
      <div className='navbar-right'>
        <NavLink
          to='/'
          exact
          id='navbar-home-link'
          className='navlink navlink-home'
          activeStyle={{ color: "#f7f7f7", fontWeight: "bold" }}>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            className='navbar-icon'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z'
              stroke='#EFEFEF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M9 22V12H15V22'
              stroke='#EFEFEF'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <span>HOME</span>
        </NavLink>
        <NavLink
          to='/account'
          exact
          id='navbar-account-link'
          className='navlink navlink-account'
          title='Account'
          activeStyle={{ color: "#f7f7f7", fontWeight: "bold" }}>
          <svg
            width='26'
            height='26'
            viewBox='0 0 26 26'
            className='navbar-icon'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M13.0001 2.16663C7.12733 2.16663 2.16675 7.12721 2.16675 13C2.16675 16.4547 3.89141 19.5845 6.50008 21.5843V21.6666H6.61058C8.41758 23.0154 10.6308 23.8333 13.0001 23.8333C15.3693 23.8333 17.5826 23.0154 19.3896 21.6666H19.5001V21.5843C22.1087 19.5845 23.8334 16.4558 23.8334 13C23.8334 7.12721 18.8728 2.16663 13.0001 2.16663ZM8.74692 20.5042C8.90656 19.7821 9.30758 19.1359 9.88384 18.6723C10.4601 18.2087 11.1772 17.9553 11.9167 17.954H14.0834C14.823 17.9555 15.5399 18.2089 16.1161 18.6725C16.6923 19.1361 17.0934 19.7821 17.2532 20.5042C15.9879 21.2376 14.5341 21.6666 13.0001 21.6666C11.4661 21.6666 10.0122 21.2376 8.74692 20.5042ZM19.0776 19.111C18.6644 18.1272 17.9701 17.2872 17.0818 16.696C16.1935 16.1048 15.1505 15.7887 14.0834 15.7874H11.9167C10.8497 15.7887 9.8067 16.1048 8.91837 16.696C8.03004 17.2872 7.33581 18.1272 6.92258 19.111C5.33333 17.5305 4.33341 15.3573 4.33341 13C4.33341 8.30154 8.30166 4.33329 13.0001 4.33329C17.6985 4.33329 21.6667 8.30154 21.6667 13C21.6667 15.3573 20.6668 17.5305 19.0776 19.111Z'
              fill='#EFEFEF'
            />
            <path
              d='M13.0001 6.5C10.5301 6.5 8.66675 8.36333 8.66675 10.8333C8.66675 13.3033 10.5301 15.1667 13.0001 15.1667C15.4701 15.1667 17.3334 13.3033 17.3334 10.8333C17.3334 8.36333 15.4701 6.5 13.0001 6.5ZM13.0001 13C11.7239 13 10.8334 12.1095 10.8334 10.8333C10.8334 9.55717 11.7239 8.66667 13.0001 8.66667C14.2762 8.66667 15.1667 9.55717 15.1667 10.8333C15.1667 12.1095 14.2762 13 13.0001 13Z'
              fill='#EFEFEF'
            />
          </svg>
          <span>ACCOUNT</span>
        </NavLink>
        <div className='navbar-logo'>
          <img src={SmartZeeLogo} alt='logo' />
        </div>
      </div>
    </div>
  );
};

export default Navbar;

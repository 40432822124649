import React, { useState, useEffect, createRef, Fragment } from "react";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
// Keep it as datepicker will return an error without alpha import
import { alpha } from "@material-ui/core/styles";
import ClickRipple from "../ClickRipple/ClickRipple";
import Toggler from "../Toggler/Toggler";
import editIcon from "../../images/home_edit_icon.svg";
import deleteIcon from "../../images/home_delete_icon.svg";
import saveIcon from "../../images/time_save_icon.svg";
import cancelIcon from "../../images/time_cancel_icon.svg";
import editIconMobile from "../../images/time_table_mobile/edit.svg";
import deleteIconMobile from "../../images/time_table_mobile/delete.svg";
import saveIconMobile from "../../images/time_table_mobile/save.svg";
import cancelIconMobile from "../../images/time_table_mobile/cancel.svg";
import timeClockIcon from "../../images/time_clock_icon.png";

const TimeRow = (props) => {
  const [date, setDate] = useState("");
  const [momentDate, setMomentDate] = useState({});
  const [startTime, setStartTime] = useState("");
  const [startTimeAsDate, setStartTimeAsDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const [endTimeAsDate, setEndTimeAsDate] = useState("");
  const [setpoint, setSetpoint] = useState("");
  const [timeRecord, setTimeRecord] = useState({
    start_time: null,
    end_time: null,
    setpoint: null,
    repeat: { flag: null, days: [] },
  });
  const [repeatFlag, setRepeatFlag] = useState(false);
  const [repeatDays, setRepeatDays] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [dateIsValid, setDateIsValid] = useState(true);
  const [isClicked, setIsClicked] = useState("");
  const [event, setEvent] = useState(null);

  const dateInput = createRef();

  const daysArr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const isTouchscreen = matchMedia("(hover: none)").matches;

  const convertToEpoch = (date, time) => {
    // date as YYYY/MM/DD
    // time as hh:mm
    let hourMins = time;
    let split = hourMins.split(":");
    let minutes = +split[0] * 60 + +split[1];
    let d = new Date(date);
    d.setMinutes(d.getMinutes() + minutes);
    const epoch = Date.parse(d.toUTCString()) / 1000;
    return epoch;
  };

  useEffect(() => {
    if (
      (props.isMobile || props.isSmlMobile) &&
      props.repeatDaysRecIndexModal === props.index
    ) {
      if (props.repeatDaysModal.length === 0 && !props.showMobileModal) {
        setRepeatFlag(false);
        setTimeRecord({
          ...timeRecord,
          repeat: { flag: 0, days: [] },
        });
      } else {
        setRepeatFlag(true);
        setTimeRecord({
          ...timeRecord,
          repeat: { flag: timeRecord.repeat.flag, days: props.repeatDaysModal },
        });
        props.setMessage("");
        setSaveDisabled(false);
      }
    }
  }, [props.repeatDaysModal]);

  useEffect(() => {
    if (!editMode) {
      setDate(props.date);
      setMomentDate(props.date);
      setStartTime(props.startTime);
      setStartTimeAsDate(props.startTimeAsDate);
      setEndTime(props.endTime);
      setEndTimeAsDate(props.endTimeAsDate);
      setSetpoint(props.setpoint);
      setRepeatFlag(props.repeat.flag === 1 ? true : false);
      setRepeatDays(props.repeat.days);
      setTimeRecord({
        start_time: props.startTimeAsDate.getTime() / 1000,
        end_time: props.endTimeAsDate.getTime() / 1000,
        setpoint: props.setpoint,
        repeat: props.repeat,
      });
    }
  }, [props.index, props.timeTable]);

  const handleChangeDate = (val) => {
    let momentDateStr = val._d;
    setDate(
      momentDateStr.getFullYear() +
        "/" +
        (momentDateStr.getMonth() + 1) +
        "/" +
        momentDateStr.getDate()
    );
    setMomentDate(val);
    setTimeRecord({
      ...timeRecord,
      start_time: convertToEpoch(val, startTime),
      end_time: convertToEpoch(val, endTime),
    });
  };

  const checkTime = (stime, etime) => {
    if (etime == "00:00") {
      return (
        Date.parse("01/01/1000 " + stime) < Date.parse("02/01/1000 " + etime)
      );
    }
    return (
      Date.parse("01/01/1000 " + stime) < Date.parse("01/01/1000 " + etime)
    );
  };

  const checkSP = (val) => {
    if (val >= 0 && val <= 100) {
      return true;
    } else if (val > 100) {
      return false;
    }
  };

  const checkRepeat = (flag, days) => {
    if (flag === true && days.length !== 0) {
      return true;
    } else if (flag === false) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeStartTime = (val) => {
    // Change full datetime from MUI time-picker to normal date (unneeded) and time hh:mm time
    let dt = new Date(val);

    const hrs = dt.getHours(); //getUTCHours() for GMT time
    const mins = dt.getMinutes(); //getUTCMinutes() for GMT time
    let hourMinuteTime =
      (hrs < 10 ? "0" + hrs : hrs) + ":" + (mins < 10 ? "0" + mins : mins);

    let dateArr = date.split("/");

    // Get correct date object from row date and MUI time
    let d = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], hrs, mins);

    setStartTime(hourMinuteTime);
    setStartTimeAsDate(d);

    if (checkTime(hourMinuteTime, endTime)) {
      setSaveDisabled(false);
      props.setMessage("");
      setTimeRecord({
        ...timeRecord,
        start_time: d.getTime() / 1000,
        end_time: convertToEpoch(date, endTime),
      });
    } else {
      setSaveDisabled(true);
      props.setMessage("End time must be ahead of Start time for the same day");
    }
  };

  const handleChangeEndTime = (val) => {
    // Change full datetime from MUI time-picker to normal date (unneeded) and time hh:mm time
    let dt = new Date(val);

    const hrs = dt.getHours(); //getUTCHours() for GMT time
    const mins = dt.getMinutes(); //getUTCMinutes() for GMT time
    let hourMinuteTime =
      (hrs < 10 ? "0" + hrs : hrs) + ":" + (mins < 10 ? "0" + mins : mins);

    let dateArr = date.split("/");

    // Get correct date object from row date and MUI time
    let d = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], hrs, mins);

    setEndTime(hourMinuteTime);
    setEndTimeAsDate(d);

    if (checkTime(startTime, hourMinuteTime)) {
      setSaveDisabled(false);
      props.setMessage("");
      if (hourMinuteTime == "00:00") {
        setTimeRecord({
          ...timeRecord,
          end_time: d.getTime() / 1000 + 24 * 60 * 60,
        });
      } else {
        setTimeRecord({
          ...timeRecord,
          start_time: convertToEpoch(date, startTime),
          end_time: d.getTime() / 1000,
        });
      }
    } else {
      setSaveDisabled(true);
      props.setMessage("End time must be ahead of Start time for the same day");
    }
  };

  const handleChangeSP = (e) => {
    let val;
    e.target.value.charAt(0) === "."
      ? (val = 0)
      : (val = Number(e.target.value.replace(/[^\d.]+/gi, "")));
    setSetpoint(val);
    if (checkSP(val)) {
      props.setMessage("");
      setSaveDisabled(false);
      setTimeRecord({ ...timeRecord, setpoint: val });
    } else {
      props.setMessage("SP cannot be greater than 100%");
      setSaveDisabled(true);
    }
  };

  const handleDateError = (err) => {
    // Show error Message
    if (err.length > 0 && document.activeElement === dateInput.current) {
      // This last check is to enable error msg only when date input is focused
      // as for some reason, the Invalid Date Format err msg appears on load when no err is there yet
      props.setMessage(err);
    }
    // Set data valid
    if (err.length > 0) {
      setDateIsValid(false);
    } else {
      setDateIsValid(true);
    }
  };

  const handleDateSuccess = () => {
    props.setMessage("");
  };

  const handleEditRecord = () => {
    setEditMode(true);
    props.alterEditedRowsArr(props.index);
    setInputDisabled(false);
    props.setSubmitIsDisabled(true);
  };

  const handleSaveChanges = () => {
    setEditMode(false);
    props.alterEditedRowsArr(props.index);
    setInputDisabled(true);
    props.getTimeRowData(props.index, timeRecord);
    props.setSubmitIsDisabled(false);
  };

  const handleCancelChanges = () => {
    setEditMode(false);
    props.alterEditedRowsArr(props.index);
    setInputDisabled(true);
    setDate(props.date);
    setMomentDate(props.date);
    setStartTime(props.startTime);
    setEndTime(props.endTime);
    setStartTimeAsDate(props.startTimeAsDate);
    setEndTimeAsDate(props.endTimeAsDate);
    setSetpoint(props.setpoint);
    setSaveDisabled(false);
    setRepeatFlag(props.repeat.flag === 1 ? true : false);
    setRepeatDays(props.repeat.days);
    // Code works without it as you do not send the updated time row to parent unless you save row
    // But it is better to keep it to keep time row's state always up-to-date with reality
    setTimeRecord({
      start_time: props.startTimeAsDate.getTime() / 1000,
      end_time: props.endTimeAsDate.getTime() / 1000,
      setpoint: props.setpoint,
      repeat: props.repeat,
    });
    props.setSubmitIsDisabled(false);
    props.setMessage("");
  };

  const handleSingleCheck = (e) => {
    let name = e.target.name;
    let newArr;
    if (!repeatDays.includes(name)) {
      newArr = [...repeatDays];
      newArr.push(name);
      setRepeatDays(newArr);
    } else {
      newArr = [...repeatDays];
      newArr.splice(repeatDays.indexOf(name), 1);
      setRepeatDays(newArr);
    }
    setTimeRecord({
      ...timeRecord,
      repeat: { flag: timeRecord.repeat.flag, days: newArr },
    });
    if (newArr.length == 0) {
      setRepeatFlag(false);
      setRepeatDays([]);
      setTimeRecord({
        ...timeRecord,
        repeat: { flag: 0, days: [] },
      });
    } else {
      props.setMessage("");
      setSaveDisabled(false);
    }
  };

  const handleRepeatToggle = (val) => {
    props.setRepeatDaysRecIndexModal(props.index);
    setTimeRecord({
      ...timeRecord,
      repeat: {
        flag: val ? 1 : 0,
        days: val ? [...timeRecord.repeat.days] : [],
      },
    });
    // Toggler off
    if (!val) {
      // setRepeatDays([]);
      props.setMessage("");
      setSaveDisabled(false);
    } else {
      // Toggler On
      setRepeatDays(props.repeat.days);
      if (props.repeat.days.length === 0) {
        setSaveDisabled(true);
        props.setMessage("Please select at least one day");
        setTimeout(() => {
          props.setMessage("");
        }, 3000);
      }
    }
  };

  const JSXDateInput = (
    <KeyboardDatePicker
      format='DD/MM/yyyy'
      onChange={handleChangeDate}
      value={momentDate}
      disabled={inputDisabled}
      onError={handleDateError}
      onKeyUp={handleDateSuccess}
      inputRef={dateInput}
      // To disable keyboard input
      // InputProps={{ readOnly: true }}
      InputProps={{ id: `config-time-record-date-input-${props.index + 1}` }}
      KeyboardButtonProps={{
        id: `config-time-record-date-picker-${props.index + 1}`,
      }}
    />
  );

  const JSXStartTimeInput = (
    <KeyboardTimePicker
      value={startTimeAsDate}
      onChange={handleChangeStartTime}
      disabled={inputDisabled}
      keyboardIcon={<img src={timeClockIcon} />}
      InputProps={{
        id: `config-time-record-start-time-input-${props.index + 1}`,
      }}
      KeyboardButtonProps={{
        id: `config-time-record-start-time-picker-${props.index + 1}`,
      }}
    />
  );

  const JSXEndTimeInput = (
    <KeyboardTimePicker
      value={endTimeAsDate}
      onChange={handleChangeEndTime}
      disabled={inputDisabled}
      keyboardIcon={<img src={timeClockIcon} />}
      InputProps={{
        id: `config-time-record-end-time-input-${props.index + 1}`,
      }}
      KeyboardButtonProps={{
        id: `config-time-record-end-time-picker-${props.index + 1}`,
      }}
    />
  );

  const JSXSPInput = (
    <input
      id={`config-time-record-setpoint-input-${props.index + 1}`}
      className='time-input'
      type='text'
      inputMode='numeric'
      pattern='[0-9]*'
      size='2'
      onChange={handleChangeSP}
      value={setpoint}
      disabled={inputDisabled}
    />
  );

  const JSXTogglerMobile = (
    <Toggler
      id={`config-time-record-repeat-toggler-mobile-${props.index + 1}`}
      mode='repeat'
      remote={repeatFlag}
      setRepeatFlag={setRepeatFlag}
      repeatDays={repeatDays}
      setRepeatDays={setRepeatDays}
      handleRepeatToggle={handleRepeatToggle}
      disabled={inputDisabled}
      setMessage={props.setMessage}
      showMobileModal={props.showMobileModal}
      setShowMobileModal={props.setShowMobileModal}
      setRepeatDaysModal={props.setRepeatDaysModal}
    />
  );

  return !props.isMobile && !props.isSmlMobile ? (
    // Desktop Time Row
    <tr
      key={props.index}
      className='table-row-item'
      style={{ backgroundColor: !editMode && "#22313c" }}>
      <td>
        <span id={`config-time-record-serial-input-desktop-${props.index + 1}`}>
          {props.index + 1}
        </span>
      </td>
      <td>{JSXDateInput}</td>
      <td>{JSXStartTimeInput}</td>
      <td>{JSXEndTimeInput}</td>
      <td>{JSXSPInput}</td>
      <td className='time-toggler'>
        <Toggler
          id={`config-time-record-repeat-toggler-desktop-desktop-${
            props.index + 1
          }`}
          mode='repeat'
          remote={repeatFlag}
          setRepeatFlag={setRepeatFlag}
          handleRepeatToggle={handleRepeatToggle}
          disabled={inputDisabled}
          setMessage={props.setMessage}
        />
      </td>
      <td style={{ padding: "0 12px" }}>
        <div className='time-days'>
          {daysArr.map((day, index) => (
            <td key={index} className='time-day'>
              <input
                id={`config-time-add-record-days-${day}-input-${
                  props.index + 1
                }`}
                className='checkbox'
                type='checkbox'
                name={day}
                checked={repeatDays.includes(day)}
                onChange={handleSingleCheck}
                disabled={inputDisabled || !repeatFlag}
              />
            </td>
          ))}
        </div>
      </td>
      <td className='device-controls'>
        <img
          id={`config-time-delete-record-btn-desktop-${props.index + 1}`}
          src={deleteIcon}
          alt='delete device'
          style={{ display: inputDisabled ? "block" : "none" }}
          onClick={() => {
            props.deleteRow(props.index);
          }}
        />
        <img
          id={`config-time-edit-record-btn-desktop-${props.index + 1}`}
          src={editIcon}
          alt='edit device'
          style={{ display: editMode ? "none" : "block" }}
          onClick={handleEditRecord}
        />
        <img
          id={`config-time-cancel-record-btn-desktop-${props.index + 1}`}
          src={cancelIcon}
          alt='cancel edit'
          style={{
            display: editMode ? "block" : "none",
          }}
          onClick={handleCancelChanges}
        />
        <img
          id={`config-time-save-record-btn-desktop-${props.index + 1}`}
          src={saveIcon}
          alt='save device'
          style={{
            display: editMode ? "block" : "none",
            cursor:
              !dateIsValid ||
              saveDisabled ||
              !checkTime(startTime, endTime) ||
              !checkSP(setpoint) ||
              !checkRepeat(repeatFlag, repeatDays)
                ? "not-allowed"
                : "",
          }}
          onClick={() => {
            if (
              dateIsValid &&
              !saveDisabled &&
              checkTime(startTime, endTime) &&
              checkSP(setpoint) &&
              checkRepeat(repeatFlag, repeatDays)
            ) {
              handleSaveChanges();
            }
          }}
        />
        <span
          id={`config-time-save-record-btn-dot-desktop-${props.index + 1}`}
          style={{
            display: !isTouchscreen ? "none" : "inline-block",
            position: "relative",
          }}
          className={
            !dateIsValid || saveDisabled
              ? "not-allowed"
              : dateIsValid === null || saveDisabled === null
              ? ""
              : "allowed"
          }></span>
      </td>
    </tr>
  ) : props.isMobile && !props.isSmlMobile ? (
    // Tab / Large Mobile Time Row
    <Fragment>
      <tr
        key={props.index}
        className='table-row-item'
        style={{ backgroundColor: !editMode && "#22313c" }}>
        <td>
          <span
            id={`config-time-record-serial-input-lg-mobile-${props.index + 1}`}>
            {props.index + 1}
          </span>
        </td>
        <td id='date-time-td'>
          <div className='date-time-col date-time-col-date flex-it'>
            {JSXDateInput}
          </div>
          <div className='date-time-col date-time-col-time flex-it'>
            <div className='date-time-col-half flex-it'>
              {JSXStartTimeInput}
            </div>
            <div className='date-time-col-half flex-it'>{JSXEndTimeInput}</div>
          </div>
        </td>
        <td>{JSXSPInput}</td>
        <td className='time-toggler'>{JSXTogglerMobile}</td>
        <td className='device-controls'>
          <div className='device-controls-wrapper'>
            <span
              id={`config-time-delete-record-btn-lg-mobile-${props.index + 1}`}
              style={{
                position: "relative",
                overflow: "visible",
                display: inputDisabled ? "block" : "none",
              }}>
              <img
                src={deleteIconMobile}
                alt='delete device'
                onClick={(e) => {
                  setIsClicked("delete");
                  setEvent(e);
                  setTimeout(() => {
                    setIsClicked("");
                    setEvent(null);
                    props.deleteRow(props.index);
                  }, 200);
                }}
              />
              {isClicked === "delete" && <ClickRipple event={event} />}
            </span>
            <span
              id={`config-time-cancel-record-btn-lg-mobile-${props.index + 1}`}
              style={{
                position: "relative",
                overflow: "visible",
                display: editMode ? "block" : "none",
              }}>
              <img
                src={cancelIconMobile}
                alt='cancel edit'
                onClick={(e) => {
                  setIsClicked("cancel");
                  setEvent(e);
                  setTimeout(() => {
                    setIsClicked("");
                    setEvent(null);
                    handleCancelChanges();
                  }, 200);
                }}
              />
              {isClicked === "cancel" && <ClickRipple event={event} />}
            </span>
            <span
              id={`config-time-edit-record-btn-lg-mobile-${props.index + 1}`}
              style={{
                position: "relative",
                overflow: "visible",
                display: editMode ? "none" : "block",
              }}>
              <img
                src={editIconMobile}
                alt='edit device'
                onClick={(e) => {
                  setIsClicked("edit");
                  setEvent(e);
                  setTimeout(() => {
                    setIsClicked("");
                    setEvent(null);
                    handleEditRecord();
                  }, 200);
                }}
              />
              {isClicked === "edit" && <ClickRipple event={event} />}
            </span>
            <span
              id={`config-time-save-record-btn-dot-lg-mobile-${
                props.index + 1
              }`}
              className={
                !dateIsValid || saveDisabled
                  ? "not-allowed"
                  : dateIsValid === null || saveDisabled === null
                  ? ""
                  : "allowed"
              }
              style={{
                position: "relative",
                overflow: "visible",
                display: editMode ? "block" : "none",
                cursor: (!dateIsValid || saveDisabled) && "not-allowed",
              }}>
              <img
                id='save-icon-mobile'
                src={saveIconMobile}
                alt='save device'
                onClick={(e) => {
                  setIsClicked("save");
                  setEvent(e);
                  setTimeout(() => {
                    setIsClicked("");
                    setEvent(null);
                    if (dateIsValid && !saveDisabled) {
                      handleSaveChanges();
                    }
                  }, 200);
                }}
              />
              {isClicked === "save" && <ClickRipple event={event} />}
            </span>
          </div>
        </td>
      </tr>
      {repeatDays.length !== 0 && (
        <td className='selected-days-mobile' colSpan='5'>
          {repeatDays.map((day, index) => (
            <span
              id={`config-time-sel-repeat-days-lg-mobile-${day}`}
              key={index}>
              {day}
            </span>
          ))}
        </td>
      )}
    </Fragment>
  ) : props.isSmlMobile && !props.isMobile ? (
    // Small Mobile Time Row
    <Fragment>
      <tr
        key={props.index}
        className='table-row-item'
        style={{ backgroundColor: !editMode && "#22313c" }}>
        <td>
          <span
            id={`config-time-record-serial-input-sml-mobile-${
              props.index + 1
            }`}>
            {props.index + 1}
          </span>
        </td>
        <td id='date-time-td'>
          <div className='date-time-col date-time-col-date flex-it'>
            {JSXDateInput}
          </div>
          <div className='date-time-col date-time-col-time flex-it'>
            <div className='date-time-col-half flex-it'>
              {JSXStartTimeInput}
            </div>
            <div className='date-time-col-half flex-it'>{JSXEndTimeInput}</div>
          </div>
        </td>
        <td>{JSXSPInput}</td>
        <td className='time-toggler'>{JSXTogglerMobile}</td>
      </tr>
      <tr>
        {repeatDays.length !== 0 && (
          <td className='selected-days-mobile' colSpan='4'>
            {repeatDays.map((day, index) => (
              <span
                id={`config-time-sel-repeat-days-sml-mobile-${day}`}
                key={index}>
                {day}
              </span>
            ))}
          </td>
        )}
      </tr>
      <td className='device-controls' colSpan='4'>
        <div className='device-controls-wrapper'>
          <div className='device-controls-group flex-it'>
            <div
              id={`config-time-save-record-btn-sml-mobile-${props.index + 1}`}
              style={{
                display: editMode ? "block" : "none",
                cursor: (!dateIsValid || saveDisabled) && "not-allowed",
                position: "relative",
                overflow: "visible",
              }}
              className={
                !dateIsValid || saveDisabled
                  ? "not-allowed"
                  : dateIsValid === null || saveDisabled === null
                  ? ""
                  : "allowed"
              }
              onClick={(e) => {
                setIsClicked("save");
                setEvent(e);
                setTimeout(() => {
                  setIsClicked("");
                  setEvent(null);
                  if (dateIsValid && !saveDisabled) {
                    handleSaveChanges();
                  }
                }, 200);
              }}>
              <img
                id='save-icon-mobile'
                src={saveIconMobile}
                alt='save device'
              />
              <span>SAVE</span>
              {isClicked === "save" && <ClickRipple event={event} />}
            </div>
            <div
              id={`config-time-edit-record-btn-sml-mobile-${props.index + 1}`}
              style={{
                display: editMode ? "none" : "block",
                position: "relative",
                overflow: "visible",
              }}
              onClick={(e) => {
                setIsClicked("edit");
                setEvent(e);
                setTimeout(() => {
                  setIsClicked("");
                  setEvent(null);
                  handleEditRecord();
                }, 200);
              }}>
              <img src={editIconMobile} alt='edit device' />
              <span>EDIT</span>
              {isClicked === "edit" && <ClickRipple event={event} />}
            </div>
          </div>
          <div className='device-controls-group flex-it'>
            <div
              id={`config-time-delete-record-btn-sml-mobile-${props.index + 1}`}
              style={{
                display: inputDisabled ? "block" : "none",
                position: "relative",
                overflow: "visible",
              }}
              onClick={(e) => {
                setIsClicked("delete");
                setEvent(e);
                setTimeout(() => {
                  setIsClicked("");
                  setEvent(null);
                  props.deleteRow(props.index);
                }, 200);
              }}>
              <img src={deleteIconMobile} alt='delete device' />
              <span>DELETE</span>
              {isClicked === "delete" && <ClickRipple event={event} />}
            </div>
            <div
              id={`config-time-cancel-record-btn-sml-mobile-${props.index + 1}`}
              style={{
                display: editMode ? "block" : "none",
                position: "relative",
                overflow: "visible",
              }}
              onClick={(e) => {
                setIsClicked("cancel");
                setEvent(e);
                setTimeout(() => {
                  setIsClicked("");
                  setEvent(null);
                  handleCancelChanges();
                }, 200);
              }}>
              <img src={cancelIconMobile} alt='cancel edit' />
              <span>CANCEL</span>
              {isClicked === "cancel" && <ClickRipple event={event} />}
            </div>
          </div>
        </div>
      </td>
    </Fragment>
  ) : null;
};

export default TimeRow;

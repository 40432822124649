import React, { useState, useEffect, Fragment } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import Home from "./pages/Home";
import Verify from "./pages/Verify";
import Account from "./pages/Account";
import Auth from "./components/Auth/Auth";
import Navbar from "./components/Navbar/Navbar";
import AdminDash from "./components/AdminDash/AdminDash";
import NotFound404 from "./components/404NotFound/NotFound404";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useMediaQuery } from "react-responsive";
import "./App.css";

const App = () => {
  const [theme, setTheme] = useState("dark");
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState(null);
  const [userIsVerified, setUserIsVerified] = useState(null);
  const [userIsSubscribed, setUserIsSubscribed] = useState(null);
  const [userUnAuthorized, setUserUnauthorized] = useState(null);
  const [isVerifyLink, setIsVerifyLink] = useState(false);
  const [loaded, setLoaded] = useState(null);
  const [URLType, setURLType] = useState("");
  const [URLCode, setURLCode] = useState("");
  const [URLPath, setURLPath] = useState("");

  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1365 });

  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  });

  useEffect(() => {
    let token =
      localStorage.getItem("token") || sessionStorage.getItem("token");
    if (token) {
      setAuthenticated(true);
      setToken(token);
    } else {
      setAuthenticated(false);
    }
  }, []);

  // Make sure that returning setters from child components are received before firing <Home> getDeviceList and <Account> getUserData requests
  // As they will be fired 3 time without this technique, as <App> will render 3 times on init causing <Home> and <Account> to render 3 times
  useEffect(() => {
    userEmail !== null &&
      userIsVerified !== null &&
      userIsSubscribed !== null &&
      setLoaded(true);
  }, [userEmail, userIsVerified, userIsSubscribed]);

  useEffect(() => {
    let signUpURL = "signup/verify";
    let changeEmailURL = "email/change/verify";
    let passwordResetURL = "password/reset";
    let signUpCode = window.location.href.indexOf(signUpURL);
    let changeEmail = window.location.href.indexOf(changeEmailURL);
    let passwordReset = window.location.href.indexOf(passwordResetURL);
    let code = new URLSearchParams(window.location.search).get("code");
    if (code) {
      setIsVerifyLink(true);
      setURLCode(code);
      if (signUpCode > -1) {
        setURLType("signupVerify");
        setURLPath(signUpURL);
      }
      if (changeEmail > -1) {
        setURLType("emailChange");
        setURLPath(changeEmailURL);
      }
      if (passwordReset > -1) {
        setURLType("passwordReset");
        setURLPath(passwordResetURL);
      }
    }
  }, []);

  const authenticate = () => {
    setAuthenticated(true);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Scrollbars
        autoHide
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{ ...style, backgroundColor: "gray", borderRadius: "30px" }}
          />
        )}>
        <Fragment>
          <Themer theme={theme} />
          {isAuthenticated && !isVerifyLink ? (
            <Fragment>
              <BrowserRouter>
                <Navbar
                  setAuthenticated={setAuthenticated}
                  setToken={setToken}
                  setUserIsVerified={setUserIsVerified}
                  setUserIsSubscribed={setUserIsSubscribed}
                  setUserEmail={setUserEmail}
                  setLoaded={setLoaded}
                  setUserUnauthorized={setUserUnauthorized}
                />
                <Switch>
                  <Route
                    path='/'
                    exact
                    component={() => (
                      <Home
                        setAuthenticated={setAuthenticated}
                        userId={userId}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        userIsVerified={userIsVerified}
                        userIsSubscribed={userIsSubscribed}
                        userEmail={userEmail}
                        loaded={loaded}
                        setLoaded={setLoaded}
                        userUnAuthorized={userUnAuthorized}
                        setUserUnauthorized={setUserUnauthorized}
                      />
                    )}
                  />
                  <Route
                    path='/account'
                    component={() => (
                      <Account
                        setAuthenticated={setAuthenticated}
                        token={token}
                        setToken={setToken}
                        isMobile={isMobile}
                        userIsVerified={userIsVerified}
                        loaded={loaded}
                      />
                    )}
                  />
                  <Route
                    path='/staff'
                    component={() => (
                      <AdminDash
                        isMobile={isMobile}
                        isTablet={isTablet}
                        loaded={loaded}
                        setLoaded={setLoaded}
                      />
                    )}
                  />
                  <Route component={NotFound404} />
                </Switch>
              </BrowserRouter>
            </Fragment>
          ) : isVerifyLink ? (
            <Verify URLType={URLType} URLCode={URLCode} URLPath={URLPath} />
          ) : (
            <div style={{ height: "calc(100vh - 140px)" }}>
              <Auth
                authenticate={authenticate}
                setToken={setToken}
                setUserId={setUserId}
              />
            </div>
          )}
        </Fragment>
      </Scrollbars>
    </MuiPickersUtilsProvider>
  );
};

export default App;

const Themer = ({ theme }) => {
  return (
    <style>
      {`
      :root {
        --color-bg:${theme === "light" ? "#EFEFEF" : " #202B33"};
        --color-primary:${theme === "light" ? "#2196F3" : " #2196F3"};
        --color-secondary:${theme === "light" ? "#1DE9B6" : " #1DE9B6"};
        --color-shadows:${theme === "light" ? "#FFFFFF" : " #161E23"};
        --color-borders-lines:${theme === "light" ? "#4A4E5C" : " #4A4E5C"};
        --color-dark-text:${theme === "light" ? "#202B33" : " #6F7281"};
        --color-light-text:${theme === "light" ? "#6F7281" : " #A4A5AA"};
        --color-lighter-text:${theme === "light" ? "#202B33" : " #EFEFEF"};
        --color-footer-bg:${theme === "light" ? "#31404A" : " #31404A"};
        --color-menu-border-dark:${theme === "light" ? "#30354A" : " #161E23"};
        --color-menu-border-light:${theme === "light" ? "#FFFFFF" : " #30354A"};
        --color-hover:${theme === "light" ? "#000000" : " #CCCCCC"};
        --color-settings-read-only:${
          theme === "light" ? "rgb(127,136,168)" : " #232e35"
        };
      }
      ${
        theme === "light"
          ? ".theme-switcher { background-color: #A4A5AA; }"
          : ""
      }
      ${theme === "light" ? ".sidenav a { font-weight: 600; }" : ""}
      ${theme === "light" ? ".ct-grid { stroke: rgba(0,0,0,0.1); }" : ""}
      ${
        theme === "light"
          ? "img.svg_embedded_img { filter: brightness(0) saturate(100%) invert(12%) sepia(16%) saturate(1046%) hue-rotate(162deg) brightness(91%) contrast(88%); }"
          : ""
      }
    `}
    </style>
  );
};
